import { FileCommonIcon, FileDeleteIcon, FileDownloadIcon, FilePreviewIcon } from 'assets/icons';
import useMediaQuery from 'hooks/useMediaQuery';
import { FC } from 'react';
import { viewFileSize } from 'utils/helpers';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { FileProps } from './types';

export const File: FC<FileProps> = ({
  file,
  onDownload,
  onPreview,
  onDelete,
  disabled = false,
  isHideDeleteFileButton = false,
  isHidePreviewFileButton = false,
  isHideDownloadFileButton = false,
}) => {
  // const hideDeleteFileButton = isHideDeleteFileButton || !file.deletedFileIdHash;
  const hideDeleteFileButton = isHideDeleteFileButton;
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  return (
    <div className="mb-1 flex items-center justify-between rounded-[0.625rem] border border-light-50 p-3 last-of-type:mb-0">
      <div className="mr-2 self-center">
        <FileCommonIcon />
      </div>
      <div className="callout-normal flex grow flex-col text-ellipsis break-all !text-text-100 sm:text-xs md:text-base">
        <span>{file.fileName}</span>
        <span className="footnote-normal text-text-50">
          {file.length ? viewFileSize(file.length) : ''}
        </span>
      </div>
      <div className="flex items-center gap-3">
        {onPreview && !isHidePreviewFileButton && !isSmall && (
          <button
            type="button"
            className="flex h-5 w-5 items-center justify-center"
            onClick={onPreview}
          >
            <FilePreviewIcon />
          </button>
        )}
        {onDownload && !isHideDownloadFileButton && (
          <button
            type="button"
            className="flex h-5 w-5 cursor-pointer items-center justify-center"
            onClick={onDownload}
          >
            <FileDownloadIcon className="text-primary-60" />
          </button>
        )}
        {onDelete && !hideDeleteFileButton && (
          <button
            type="button"
            className="flex h-5 w-5 items-center justify-center disabled:cursor-not-allowed"
            onClick={onDelete}
            disabled={disabled}
          >
            <FileDeleteIcon />
          </button>
        )}
      </div>
    </div>
  );
};
