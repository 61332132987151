import { CaseIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { getModerationRequestStatus } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestStatus';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { ProfileService } from 'services/ProfileService';
import { Step4Fields } from './Step4Fields';
import { Step4Payload } from './types';

export const Step4 = () => {
  const { status, serviceCities, executorSpecializations, acceptsOffer, parentId } =
    useAppSelector(getModerationRequestData);
  const isFormDisabled = useAppSelector(getModerationRequestStatus);
  const { created } = useAppSelector(getProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const step = pathname.split('step')[1];

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm<FieldValues>({
    defaultValues: {
      serviceCities: serviceCities?.map((item: any) => ({
        label: item.objectFullName,
        value: item,
      })),
      executorSpecializations,
      acceptsOffer,
    },
    mode: 'onBlur',
  });

  const { refetch } = useQuery(
    ['getUserCommonSettlements'],
    () => ProfileService.getUserCommonSettlements(),
    {
      onSuccess: (data) => {
        if (data.data.length > 0 && !serviceCities?.length) {
          setValue(
            'serviceCities',
            (data as any)?.data?.map((item: any) => ({
              label: item.objectFullName,
              value: item,
            }))
          );
        }
      },
    }
  );

  const onSubmit: SubmitHandler<any> = (data, event) => {
    const extracted = data?.serviceCities?.map((item: any) => {
      if ('isMarked' in item.value) {
        return { fiasId: item.value.suggestion.data.fias_id, objectFullName: item.label };
      }
      return item.value;
    });
    const newData = { ...data, serviceCities: extracted };
    dispatch(moderationRequestActions.setSettlementsAndSpecializations(newData as Step4Payload));
    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as HTMLButtonElement;
    if (submitter.name === 'forward') {
      const nextStep = +step + 1;
      navigate(`/moderation-request/step${nextStep}`);
    } else if (submitter.name === 'back') {
      const prevStep = +step - 1;
      prevStep
        ? prevStep && navigate(`/moderation-request/step${prevStep}`)
        : navigate(`/moderation-request`);
    }
  };

  return (
    <FormWrapper
      title="Специализации и территория работы"
      status={status}
      created={created}
      Icon={CaseIcon}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex min-h-[65vh] flex-col justify-between"
      >
        <Step4Fields
          clearErrors={clearErrors}
          errors={errors}
          parentId={parentId}
          control={control}
          watch={watch}
          isFormDisabled={isFormDisabled}
          setValue={setValue}
        />
        <FormControl errors={errors} />
      </form>
    </FormWrapper>
  );
};
