import { ChevronIcon, CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import useMediaQuery from 'hooks/useMediaQuery';
import { FC, useState } from 'react';
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueProps,
  OptionProps,
  SingleValueProps,
  components,
} from 'react-select';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { backgroundTypes, multiOptionStyle } from './styles';
import { OptionType } from './types';

const WithIconOption: FC<OptionProps<OptionType>> = (props) => (
  <components.Option {...props}>
    {props.data.Icon && <props.data.Icon className="mr-2" />}
    {props.data.label}
  </components.Option>
);

const SingleValue: FC<SingleValueProps<OptionType>> = ({ children, ...props }) => (
  <components.SingleValue className="flex items-center" {...props}>
    {props.data.Icon && <props.data.Icon className="mr-2" />}
    <span className="callout-normal text-text-100">{children}</span>
  </components.SingleValue>
);

const DropdownIndicator: FC<DropdownIndicatorProps<OptionType>> = (props) => (
  <components.DropdownIndicator {...props}>
    <ChevronIcon
      className={classNames('fill-text-50', {
        'rotate-180': props.isFocused,
      })}
    />
  </components.DropdownIndicator>
);

const ClearIndicator: FC<ClearIndicatorProps<OptionType>> = (props) => (
  <components.ClearIndicator {...props}>
    <CloseIcon className="-mr-3 cursor-pointer text-text-50 hover:text-text-100 sm:mr-0" />
  </components.ClearIndicator>
);

const IsMultiOption: FC<OptionProps<OptionType>> = ({
  getStyles,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  const styleProps = {
    ...multiOptionStyle,
    backgroundColor: backgroundTypes[isActive || isFocused ? 'accent' : 'default'],
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    styleProps,
  };

  return (
    <components.Option
      {...rest}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      {isSmall ? <>{children}</> : <>{children}</>}
    </components.Option>
  );
};

const MultiValue: FC<MultiValueProps<OptionType>> = ({ children, ...props }) => (
  <components.MultiValue className="flex items-center rounded-3xl bg-light-50 pl-2" {...props}>
    <span className="body-normal max-w-[80vw] whitespace-normal text-text-100">{children}</span>
  </components.MultiValue>
);

export const variantWithIconsComponents = {
  Option: WithIconOption,
  SingleValue,
  DropdownIndicator,
};

export const isMultiComponents = {
  Option: IsMultiOption,
  DropdownIndicator,
  MultiValue,
  ClearIndicator,
};
