import { OrganizationType } from 'enums/organizationType';
import { UseFormSetError } from 'react-hook-form';

export const checkInn = (
  inn: string | null,
  innScan: OrderFile | null,
  organizationTypeId: OrganizationType,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const setLLCInnError = () => {
    setError('inn', {
      type: 'manual',
      message: 'Введите валидный ИНН. ИНН должен состоять из 10 цифр',
    });
    toastErrors.push('Введите валидный ИНН. ИНН должен состоять из 10 цифр');
  };
  const setIndividualInnError = () => {
    setError('inn', {
      type: 'manual',
      message: 'Введите валидный ИНН. ИНН должен состоять из 12 цифр',
    });
    toastErrors.push('Введите валидный ИНН. ИНН должен состоять из 12 цифр');
  };
  const setInnScanError = () => {
    setError('innScan', {
      type: 'manual',
      message: 'Необходимо приложить файл',
    });
    toastErrors.push('Необходимо приложить файл');
  };

  if (organizationTypeId === OrganizationType.Individual) {
    if (inn === null) {
      setIndividualInnError();
    } else {
      inn = inn.replace(/_/g, '');
      if (inn.length < 12) {
        setIndividualInnError();
      }
      const innNumbers = inn.split('').map(Number);
      if (
        !(
          innNumbers[10] ===
            ((7 * innNumbers[0] +
              2 * innNumbers[1] +
              4 * innNumbers[2] +
              10 * innNumbers[3] +
              3 * innNumbers[4] +
              5 * innNumbers[5] +
              9 * innNumbers[6] +
              4 * innNumbers[7] +
              6 * innNumbers[8] +
              8 * innNumbers[9]) %
              11) %
              10 &&
          innNumbers[11] ===
            ((3 * innNumbers[0] +
              7 * innNumbers[1] +
              2 * innNumbers[2] +
              4 * innNumbers[3] +
              10 * innNumbers[4] +
              3 * innNumbers[5] +
              5 * innNumbers[6] +
              9 * innNumbers[7] +
              4 * innNumbers[8] +
              6 * innNumbers[9] +
              8 * innNumbers[10]) %
              11) %
              10
        )
      ) {
        setIndividualInnError();
      }
    }
    if (!innScan) {
      setInnScanError();
    }
  } else {
    if (inn === null) {
      setLLCInnError();
    } else {
      inn = inn.replace(/_/g, '');
      if (inn.length < 10) {
        setLLCInnError();
      }
      const innNumbers = inn.split('').map(Number);
      if (
        !(
          innNumbers[9] ===
          ((2 * innNumbers[0] +
            4 * innNumbers[1] +
            10 * innNumbers[2] +
            3 * innNumbers[3] +
            5 * innNumbers[4] +
            9 * innNumbers[5] +
            4 * innNumbers[6] +
            6 * innNumbers[7] +
            8 * innNumbers[8]) %
            11) %
            10
        )
      ) {
        setLLCInnError();
      }
    }
    if (!innScan || Object.keys(innScan).length === 0) {
      setInnScanError();
    }
  }
  return toastErrors;
};

export const checkPhoneNumber = (
  phoneNumber: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/_/g, '');

    if (phoneNumber.length < 16) {
      setError('phoneNumber', {
        type: 'manual',
        message: 'Телефон не соответствует шаблону',
      });
      toastErrors.push('Телефон не соответствует шаблону');
    }
  }

  return toastErrors;
};

export const checkEmail = (
  email: string,
  length: number,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  let isValid = false;

  if (email.length <= length) {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    isValid = emailRegex.test(email);

    if (isValid) {
      const arr = email.split('@');
      isValid = arr.length == 2 && arr[1].includes('.');
    }
  }

  if (!isValid) {
    setError('email', {
      type: 'manual',
      message: 'Формат Email неверный или превышает 100 символов',
    });
    toastErrors.push('Формат Email неверный или превышает 100 символов');
  }

  return toastErrors;
};

export const checkSubcontractorOgrnip = (
  ogrnip: string | null,
  ogrnipScan: OrderFile | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const setOgrnipError = () => {
    setError('ogrnip', {
      type: 'manual',
      message: 'Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр',
    });
    toastErrors.push('Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр');
  };
  const setIndividualCertificateFilesError = () => {
    setError('ogrnipScan', {
      type: 'manual',
      message: 'Необходимо приложить файл',
    });
    toastErrors.push('Необходимо приложить файл');
  };

  if (ogrnip === null) {
    setOgrnipError();
  } else {
    ogrnip = ogrnip.replace(/_/g, '');
    if (ogrnip.length < 15) {
      setOgrnipError();
    }
    if (ogrnip[0] !== '3') {
      setOgrnipError();
    }
    const targetCheckSum = Number.parseInt(ogrnip[14]);
    let checkSum = Number.parseInt(ogrnip.substring(0, 14));
    checkSum %= 13;
    if (checkSum > 9) {
      checkSum %= 10;
    }
    if (checkSum !== targetCheckSum) {
      setOgrnipError();
    }
  }

  if (!ogrnipScan) {
    setIndividualCertificateFilesError();
  }
  return toastErrors;
};

export const checkSubcontractorOgrn = (
  ogrn: string | null,
  ogrnScan: OrderFile | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const setOgrnError = () => {
    setError('ogrn', {
      type: 'manual',
      message: 'Введите валидный ОГРН. ОГРН должен состоять из 13 цифр',
    });
    toastErrors.push('Введите валидный ОГРН. ОГРН должен состоять из 13 цифр');
  };
  const setOgrnScanError = () => {
    setError('ogrnScan', {
      type: 'manual',
      message: 'Необходимо приложить файл',
    });
    toastErrors.push('Необходимо приложить файл');
  };

  if (ogrn === null) {
    setOgrnError();
  } else {
    ogrn = ogrn.replace(/_/g, '');
    if (ogrn.length < 13) {
      setOgrnError();
    }
    if (!['1', '5'].includes(ogrn[0])) {
      setOgrnError();
    }
    const targetCheckSum = Number.parseInt(ogrn[12]);
    let checkSum = Number.parseInt(ogrn.substring(0, 12));
    checkSum %= 11;

    if (checkSum > 9) {
      checkSum %= 10;
    }
    if (checkSum !== targetCheckSum) {
      setOgrnError();
    }
  }
  if (!ogrnScan) {
    setOgrnScanError();
  }
  return toastErrors;
};
