import { styled } from 'styled-components';
import { RecieptsByPeriodForm } from './RecieptsByPeriodForm';
import { RecieptsByPeriod } from '.';

export const StyledRecieptsByPeriodForm = styled(RecieptsByPeriodForm)`
  .field-upload-input-wrapper {
    display: flex;
    flex-grow: 1;
    margin-bottom: 20px;

    .field-upload-input-item {
      min-width: 350px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    .field-upload-input-wrapper {
      flex-direction: column;

      .field-upload-input-item {
        min-width: 0;

        &:first-child {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export const StyledRecieptsByPeriod = styled(RecieptsByPeriod)`
  display: flex;

  > form {
    margin-right: 20px;
  }

  @media (max-width: 880px) {
    flex-direction: column;

    > form {
      order: 1;
      margin-right: 0;
    }

    .filter {
      order: 0;
      margin-bottom: 20px;
    }
  }
`;
