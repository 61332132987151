import { useAppSelector } from 'hooks/useAppSelector';
import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';

import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { ESubcontractorModerationRequestStatusId } from 'enums/subcontractorModerationRequestStatus';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { subcontractorModerationRequestActions } from 'reduxStore/reducers/SubcontractorModerationSlice';
import { ModerationRequestService } from 'services/ModerationRequestService';
import { SubcontractorModerationRequestService } from 'services/SubcontractorModerationRequestService';
import { Spinner } from 'ui/Spinner';
import { RedesignExecutorModeration } from './ExecutorModeration/RedesignExecutorModeration';
import { FinalStep } from './ExecutorModeration/steps/finalStep';
import { RedesignSubcontractorModeration } from './SubcontractorModeration/RedesignSubcontractorModeration';
import { FinalStepSubcontractor } from './SubcontractorModeration/steps/finalStep';
import { defaultRequest } from './consts';

export default function Moderation() {
  const permissions = useAppSelector(getPermissions);
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState(true);

  const { data: moderationRequest, isFetching } = useQuery(
    ['getModerationRequest'],
    () => ModerationRequestService.getCurrentModerationRequest(),
    {
      onSuccess: async (data) => {
        dispatch(moderationRequestActions.setModerationRequest(data.data));
        if (
          [
            ModerationRequestStatusId.Draft,
            ModerationRequestStatusId.ElaborationRequested,
          ].includes(data.data.status)
        ) {
          dispatch(moderationRequestActions.setIsFormDisabled(false));
        } else {
          dispatch(moderationRequestActions.setIsFormDisabled(true));
        }
      },
      onError: async (data) => {
        // dispatch(moderationRequestActions.setModerationRequest(defaultRequest));
        dispatch(moderationRequestActions.setIsFormDisabled(false));
      },
      enabled: !permissions.includes(ProfilePermissions.VerificationQuestionary_Create),
    }
  );

  const {
    data: subcontractorModerationRequest,
    isFetching: isFetchingSubcontractorModerationRequest,
  } = useQuery(
    ['getSubcontractorModerationRequest'],
    () => SubcontractorModerationRequestService.getCurrentModerationRequest(),
    {
      onSuccess: async (data) => {
        if (data.data.statusId === ESubcontractorModerationRequestStatusId.ElaborationRequest) {
          refetch();
        } else {
          dispatch(subcontractorModerationRequestActions.setModerationRequest(data.data));
          if (
            [
              ESubcontractorModerationRequestStatusId.Refused,
              ESubcontractorModerationRequestStatusId.Registered,
              ESubcontractorModerationRequestStatusId.SendToVerification,
              ESubcontractorModerationRequestStatusId.ElaborationProvided,
              ESubcontractorModerationRequestStatusId.Signing,
            ].includes(data.data.statusId)
          ) {
            dispatch(moderationRequestActions.setIsFormDisabled(true));
          }
          if ([ESubcontractorModerationRequestStatusId.Draft].includes(data.data.statusId)) {
            dispatch(moderationRequestActions.setIsFormDisabled(false));
          }
          setIsDisabled(false);
        }
      },
      onError: async (data) => {
        setIsDisabled(false);
        // dispatch(moderationRequestActions.setModerationRequest(defaultRequest));
        // dispatch(moderationRequestActions.setIsFormDisabled(false));
      },
      enabled: permissions.includes(ProfilePermissions.VerificationQuestionary_Create),
    }
  );

  const { refetch } = useQuery(
    ['getSubcontractorModerationRequestWithCheckLists'],
    () => SubcontractorModerationRequestService.getCurrentModerationRequestWithCheckList(),
    {
      onSuccess: async (data) => {
        dispatch(subcontractorModerationRequestActions.setModerationRequest(data.data));
        setIsDisabled(false);
      },
      onError: async (data) => {
        // dispatch(moderationRequestActions.setModerationRequest(defaultRequest));
        // dispatch(moderationRequestActions.setIsFormDisabled(false));
      },
      enabled: false,
      // enabled: permissions.includes(ProfilePermissions.VerificationQuestionary_Create),
    }
  );

  return (
    <>
      {permissions.includes(ProfilePermissions.VerificationQuestionary_Create) ? (
        <>
          {!isFetchingSubcontractorModerationRequest && !isDisabled ? (
            subcontractorModerationRequest?.data.statusId ? (
              <FinalStepSubcontractor />
            ) : (
              <RedesignSubcontractorModeration />
            )
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <>
          {isFetching ? (
            <Spinner />
          ) : moderationRequest?.data.status ? (
            <FinalStep />
          ) : (
            <RedesignExecutorModeration />
          )}
        </>
      )}
    </>
  );
}
