import React, { useEffect, useState } from 'react';
import { ReactComponent as DropdownArrowIcon } from 'assets/dropdown-arrow.svg';

import { Input } from '../Input';
import { Option } from './Option';
import { OptionType } from 'ui/Select';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { ValidationError } from 'ui/redesign/ValidationError';

export interface CustomSelectProps {
  value?: never[];
  name: string;
  label: string;
  options: OptionType[];
  onChange?: (value: number[]) => void;
  errorMessage?: string;
  disabled?: boolean;
}

export const CustomSelect = ({
  value,
  options,
  name,
  label,
  onChange,
  errorMessage,
  disabled,
}: CustomSelectProps) => {
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (value) {
      setSelectedValues(value);
      const _names: string[] = [];
      value.forEach((item) => {
        _names.push(getItem(item)!.label);
      });
      setSelectedNames(_names);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange && onChange(selectedValues);
  }, [selectedValues]);

  const handleOnClick = () => {
    setOpen((prev) => !prev);
  };

  const handleConfirmClick = () => {
    setOpen(false);
  };

  const getItem = (id: number) => options.find((item) => item.value === id);

  const handleOnChange = (id: number) => {
    let _selectedValues = selectedValues;
    let names = selectedNames;

    const hasValue = selectedValues.find((value) => value === id);

    if (hasValue) {
      _selectedValues = _selectedValues.filter((value) => value !== id);
      names = names.filter((value) => value !== getItem(hasValue)!.label);
    } else {
      // Получаем массив выбранных значений
      _selectedValues = [..._selectedValues, id];
      // Получаем "значение" для отображения в поле
      names = [...names, getItem(id)!.label];
    }
    setSelectedValues(_selectedValues);
    setSelectedNames(names);
  };

  return (
    <div className="relative">
      {label && (
        <div
          className={classNames('body-normal !text-text-50', {
            '!text-primary-60': label === 'Федеральный округ',
          })}
        >
          {label}
        </div>
      )}
      <div onClick={handleOnClick} className={`relative ${disabled ? 'pointer-events-none' : ''}`}>
        <Input
          name={name}
          value={selectedNames.join(', ') || ''}
          className={classNames('headline-normal cursor-pointer truncate pt-1 pb-0.5 pr-12', {
            'text-text-50': disabled,
          })}
          readOnly
          errorMessage={errorMessage}
          disabled={disabled}
          icons={[
            <CloseIcon
              className={classNames('mt-[22px] -mr-[3px] h-3 w-3 text-text-50')}
              key={1}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedValues([]);
                setSelectedNames([]);
              }}
            />,
            <DropdownArrowIcon
              className={classNames('mt-[22px] mr-[5px] h-5 w-5 p-[3px] text-gray-dark', {
                'rotate-180': open,
              })}
              key={2}
            />,
          ]}
        />
      </div>
      {open && (
        <>
          <div className="absolute top-[68px] z-30 mt-2 w-full overflow-scroll rounded-lg bg-white shadow-xl ">
            {options.map(({ value, label, disabled }) => {
              return (
                <Option
                  key={value}
                  id={value}
                  label={label}
                  value={value}
                  onChange={(id) => handleOnChange(id)}
                  active={selectedValues.includes(value)}
                  disabled={disabled}
                />
              );
            })}
          </div>
          <div className="fixed top-0 left-0 z-10 h-full w-full" onClick={handleConfirmClick} />
        </>
      )}
    </div>
  );
};
