import { UseFormSetError } from 'react-hook-form';
import * as yup from 'yup';

export const step3SchemaSelfemployed = yup.object().shape({
  bik: yup
    .string()
    .typeError('Неверный формат БИК. БИК должен состоять из 9 цифр')
    .required('Неверный формат БИК. БИК должен состоять из 9 цифр'),
  bankSettlement: yup
    .string()
    .typeError(
      'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр'
    )
    .required(
      'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр'
    ),
  selfemployedCertificate: yup
    .array()
    .min(1, 'Необходимо приложить 1 или более файлов')
    .max(5, 'Необходимо приложить не более 5 файлов')
    .required('Необходимо приложить 1 или более файлов'),
});

export const step3SchemaIndividual = yup.object().shape({
  bik: yup
    .string()
    .typeError('Неверный формат БИК. БИК должен состоять из 9 цифр')
    .required('Неверный формат БИК. БИК должен состоять из 9 цифр'),
  bankSettlement: yup
    .string()
    .typeError(
      'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр'
    )
    .required(
      'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр'
    ),
  ogrnip: yup
    .string()
    .typeError('Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр')
    .required('Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр'),
  individualName: yup
    .string()
    .max(150, '«Наименование ИП» должно быть зафиксировано и не превышать 150 символов')
    .typeError('«Наименование ИП» должно быть зафиксировано и не превышать 150 символов')
    .required('«Наименование ИП» должно быть зафиксировано и не превышать 150 символов'),
  individualCertificateFiles: yup
    .array()
    .min(1, 'Необходимо приложить 1 или более файлов')
    .max(5, 'Необходимо приложить не более 5 файлов')
    .required('Необходимо приложить 1 или более файлов'),
});

export const checkBik = (bik: string, maskErrors: number, setError: UseFormSetError<any>) => {
  if (bik) {
    bik = bik.replace(/_/g, '');
    if (bik.length < 9) {
      setError('bik', {
        type: 'manual',
        message: 'Неверный формат БИК. БИК должен состоять из 9 цифр',
      });
      maskErrors++;
    }
  }
  return maskErrors;
};

export const checkExecutorOgrnip = (
  ogrnip: string | null,
  individualName: string | null,
  individualCertificateFiles: OrderFile[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const setOgrnipError = () => {
    setError('ogrnip', {
      type: 'manual',
      message: 'Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр',
    });
    toastErrors.push('Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр');
  };
  const setIndividualNameError = () => {
    setError('individualName', {
      type: 'manual',
      message: '«Наименование ИП» должно быть зафиксировано и не превышать 150 символов',
    });
    toastErrors.push('«Наименование ИП» должно быть зафиксировано и не превышать 150 символов');
  };
  const setIndividualCertificateFilesError = () => {
    setError('individualCertificateFiles', {
      type: 'manual',
      message: 'Необходимо приложить 1 или более файлов',
    });
    toastErrors.push('Необходимо приложить 1 или более файлов');
  };
  if (ogrnip === null) {
    setOgrnipError();
  } else {
    ogrnip = ogrnip.replace(/_/g, '');
    if (ogrnip.length < 15) {
      setOgrnipError();
    }
    if (ogrnip[0] !== '3') {
      setOgrnipError();
    }
    const targetCheckSum = Number.parseInt(ogrnip[14]);
    let checkSum = Number.parseInt(ogrnip.substring(0, 14));
    checkSum %= 13;
    if (checkSum > 9) {
      checkSum %= 10;
    }
    if (checkSum !== targetCheckSum) {
      setOgrnipError();
    }
  }

  if (individualName === null || individualName.trim().length < 1) {
    setIndividualNameError();
  } else {
    individualName = individualName.trim();
  }

  if (individualCertificateFiles === null || individualCertificateFiles.length < 1) {
    setIndividualCertificateFilesError();
  }
  return toastErrors;
};

export const checkBikAndBankSettlement = (
  bankSettlement: string | null,
  bik: string | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
): string[] => {
  const setBankSettlementError = () => {
    setError('bankSettlement', {
      type: 'manual',
      message:
        'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр',
    });
    toastErrors.push(
      'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр'
    );
  };
  const setBikError = () => {
    setError('bik', {
      type: 'manual',
      message: 'Неверный формат расчетного счета или БИК. БИК должен состоять из 9 цифр',
    });
    toastErrors.push('Неверный формат расчетного счета или БИК. БИК должен состоять из 9 цифр');
  };
  if (bankSettlement === null) {
    setBankSettlementError();
  } else {
    bankSettlement = bankSettlement.replace(/_/g, '');
  }

  if (bankSettlement === null || bankSettlement.replace(/_/g, '').length < 20) {
    setBankSettlementError();
  } else {
    bankSettlement = bankSettlement.replace(/_/g, '');
  }

  if (bik === null || bik.replace(/_/g, '').length < 9) {
    setBikError();
  } else {
    bik = bik.replace(/_/g, '');
  }
  const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

  const bikAndAccount = bik!.substring(bik!.length - 3) + bankSettlement;
  if (bikAndAccount.length !== 23) {
    setBankSettlementError();
    setBikError();
  }

  let bikSum = 0;
  for (let i = 0; i < bikAndAccount.length; i++) {
    const digit = Number.parseInt(bikAndAccount[i], 10);
    if (Number.isNaN(digit)) {
      setBankSettlementError();
      setBikError();
    }
    bikSum += digit * coefficients[i];
  }

  if (bikSum % 10 !== 0) {
    setBankSettlementError();
    setBikError();
  }
  return toastErrors;
};

export const checkOgrn = (ogrn: string, maskErrors: number, setError: UseFormSetError<any>) => {
  if (ogrn) {
    ogrn = ogrn.replace(/_/g, '');
    if (ogrn.length < 13) {
      setError('ogrn', {
        type: 'manual',
        message: 'Введите валидный ОГРН. ОГРН должен состоять из 13 цифр',
      });
      maskErrors++;
    }
  }
  return maskErrors;
};
