import { InfoCard } from '../../../components/InfoCard';
import { sliderSettings, sliderData } from './constants';
import { StyledSlider } from './styled';

export const Slider = () => {
  return (
    <StyledSlider {...sliderSettings}>
      {sliderData.map((sliderItem) => (
        <InfoCard key={sliderItem.id} infoItem={sliderItem} />
      ))}
    </StyledSlider>
  );
};
