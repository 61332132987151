import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import { ReactComponent as LeftArrowIcon } from 'assets/left-arrow.svg';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import Button from 'components/Pagination/Button';

// https://stackoverflow.com/a/65824403
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

interface IFilePreviewProps {
  file: any;
}

export const FilePreview = ({ file }: IFilePreviewProps) => {
  const Pdf = ({ data }: { data: string }) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
      setNumPages(numPages);
    }

    const prevPageColor = pageNumber === 1 ? 'text-gray-medium' : 'text-black';
    const nextPageColor = pageNumber === numPages ? 'text-gray-medium' : 'text-black';

    return (
      // <div className="min-h-[700px] w-full overflow-scroll p-4">
      <div className="mx-auto min-h-[700px] w-full overflow-auto sm:p-4">
        <div className="flex items-center justify-center gap-4">
          <Button
            onClick={() => setPageNumber((prevState) => prevState - 1)}
            disabled={pageNumber === 1}
          >
            <div className={`flex items-center justify-center gap-2 ${prevPageColor}`}>
              <LeftArrowIcon className={`h-4 w-4 ${prevPageColor}`} /> Предыдущая
            </div>
          </Button>
          <Button
            onClick={() => setPageNumber((prevState) => prevState + 1)}
            disabled={pageNumber === numPages}
          >
            <div className={`flex items-center justify-center gap-2 ${nextPageColor}`}>
              Следующая
              <LeftArrowIcon className={`h-4 w-4 rotate-180 ${nextPageColor}`} />
            </div>
          </Button>
        </div>
        <Document
          file={data}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(e) => console.log(e)}
          options={{ workerSrc: 'pdf.worker.min.js' }}
        >
          <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
        <p>
          Страница {pageNumber} из {numPages}
        </p>
      </div>
    );
  };

  const Preview = () => {
    switch (file.type) {
      case 'image/png':
      case 'image/jpg':
      case 'image/jpeg':
        return (
          <img src={`data:${file.type};base64,${file.content}`} className="w-fit" alt="image" />
        );
      case 'application/pdf':
        return <Pdf data={`data:application/pdf;base64,${file.content}`} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Preview />
    </>
  );
};
