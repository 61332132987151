import { EExecutorSpecialization } from 'enums/executorSpecialization';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { OptionType } from 'ui/Select';
import { SpecSelectProps } from './types';
import { CustomSelect } from 'ui/redesign/CustomSelect';

export const SpecSelect = ({
  name,
  label,
  onChange,
  control,
  errorMessage,
  options,
  watch,
  clearErrors,
  ...rest
}: SpecSelectProps) => {
  const [specs, setSpecs] = useState<OptionType[]>(options);
  const currentSpecializations: number[] = watch('executorSpecializations');
  useEffect(() => {
    const temp = specs;
    if (currentSpecializations?.length === 0) {
      setSpecs(options);
    } else if (currentSpecializations?.includes(EExecutorSpecialization.Agent)) {
      const specs = temp.map((elem) => {
        if (elem.value !== EExecutorSpecialization.Agent) {
          return { ...elem, disabled: true };
        } else {
          return { ...elem, disabled: false };
        }
      });
      setSpecs(specs);
    } else {
      const specs = temp.map((elem) => {
        if (elem.value === EExecutorSpecialization.Agent) {
          return { ...elem, disabled: true };
        } else {
          return { ...elem, disabled: false };
        }
      });
      setSpecs(specs);
    }
  }, [currentSpecializations]);

  return (
    <div className="field-select">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <CustomSelect
              name={name}
              label={label}
              value={field.value}
              errorMessage={errorMessage}
              onChange={(value: any) => {
                field.onChange(value);
                onChange && onChange(value);
                clearErrors && clearErrors(name);
              }}
              options={specs}
              {...rest}
            />
          );
        }}
      />
    </div>
  );
};
