import { ECitizenship } from 'enums/citizenship';
import { UseFormSetError } from 'react-hook-form';
import * as yup from 'yup';

export const citizenships = [
  { label: 'Российская Федерация', value: ECitizenship.RussianFederation },
  { label: 'Республика Казахстан', value: ECitizenship.RepublicOfKazakhstan },
  { label: 'Республика Беларусь', value: ECitizenship.RepublicOfBelarus },
  { label: 'Киргизская Республика', value: ECitizenship.KyrgyzRepublic },
  { label: 'Республика Армения', value: ECitizenship.RepublicOfArmenia },
];

const MAX_SIZE: number = 10 * 1024 * 1024;

export const step2Schema = yup.object().shape({
  // citizenship,
  series: yup
    .string()
    .typeError('Серия паспорта должна состоять из 4 цифр')
    .required('Серия паспорта должна быть заполнена'),
  number: yup
    .string()
    .typeError('Номер паспорта должен состоять из 6 цифр')
    .required('Номер паспорта должен быть заполнен'),
  dateOfIssue: yup
    .string()
    .typeError('Дата выдачи должна быть заполнена')
    .required('Дата выдачи должна быть заполнена'),
  unitCode: yup
    .string()
    .typeError('Код подразделения должен состоять из 6 цифр')
    .required('Код подразделения должен быть заполнен'),
  issuedBy: yup
    .string()
    .typeError('Поле "Кем выдан паспорт" должно быть заполнено и не превышать 250 символов')
    .max(250, 'Поле "Кем выдан паспорт" должно быть заполнено и не превышать 250 символов')
    .required('Кем выдан паспорт должно быть заполнено'),
  birthPlace: yup
    .string()
    .typeError('Поле "Место рождения" должно быть заполнено и не превышать 255 символов')
    .max(255, 'Поле "Место рождения" должно быть заполнено и не превышать 255 символов')
    .required('Поле "Место рождения" должно быть заполнено'),
  registrationAddress: yup
    .string()
    .typeError('Поле "Адрес регистрации" должно быть заполнено и не превышать 255 символов')
    .max(255, 'Поле "Адрес регистрации" должно быть заполнено и не превышать 255 символов')
    .required('Поле "Адрес регистрации" должно быть заполнено'),
  files: yup
    .array()
    .min(1, 'Приложите разворот с фотографией, страницы с пропиской')
    .max(5, 'Необходимо приложить не более 5 файлов')
    .required('Приложите разворот с фотографией, страницы с пропиской'),
  executorPhotos: yup
    .array()
    .min(1, 'Загрузите фото с паспортом, на котором видно ваше лицо и информация из паспорта')
    .max(5, 'Необходимо приложить не более 5 файлов')
    .required('Загрузите фото с паспортом, на котором видно ваше лицо и информация из паспорта'),
});

export const checkSeries = (
  series: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (series) {
    series = series.replace(/[_ ]/g, '');
    if (series.length < 4) {
      setError('series', {
        type: 'manual',
        message: 'Серия паспорта должна состоять из 4 цифр',
      });
      toastErrors.push('Серия паспорта должна состоять из 4 цифр');
    }
  }
  return toastErrors;
};

export const checkNumber = (
  number: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (number) {
    number = number.replace(/_/g, '');
    if (number.length < 6) {
      setError('number', {
        type: 'manual',
        message: 'Номер паспорта должен состоять из 6 цифр',
      });
      toastErrors.push('Номер паспорта должен состоять из 6 цифр');
    }
  }
  return toastErrors;
};

export const checkUnitCode = (
  unitCode: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (unitCode) {
    unitCode = unitCode.replace(/[_-]/g, '');
    if (unitCode.length < 6) {
      setError('unitCode', {
        type: 'manual',
        message: 'Код подразделения должен состоять из 6 цифр',
      });
      toastErrors.push('Код подразделения должен состоять из 6 цифр');
    }
  }
  return toastErrors;
};

export const checkDescription = (
  description: string,
  maskErrors: number,
  setError: UseFormSetError<any>
) => {
  if (description) {
    description = description?.trim();
    if (description.length < 1) {
      setError('description', {
        type: 'manual',
        message: 'Описание должно быть заполнено',
      });
      maskErrors++;
    }
  }
  return maskErrors;
};
