import { IconLightning } from 'assets/icons';
import { format } from 'date-fns';
import { formatAmount } from '../../utils/helpers';
import Customer from 'components/Customer';

export const nearbyRequestsTableHeadings: TableHeading[] = [
  {
    attributeName: 'clientShortName',
    name: 'Заказчик',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          <Customer name={column as string} />
        </span>
      );
    },
  },
  { attributeName: 'id', name: 'Заявка', renderColumn: (column) => `№ ${column}` },
  { attributeName: 'name', name: 'Описание' },
  {
    attributeName: 'created',
    name: 'Дата создания',
    renderColumn: (column) => format(new Date(column as string), 'dd.MM.yyyy'),
  },
  {
    attributeName: 'costForSelfEmployed',
    name: 'Стоимость',
    // renderColumn: (column) => `${formatAmount(column as number, { minimumFractionDigits: 2 })} ₽`,
    renderColumn: (column) => `${formatAmount(column as number, { minimumFractionDigits: 2 })} ₽`,
  },
  { attributeName: 'address', name: 'Адрес' },
  {
    attributeName: 'distanceInMeters',
    name: 'Расстояние, км (* не точно)',
    renderColumn: (column, rowData) => {
      const distanceInKiloMeters = column ? Number(column) / 1000 : 0;
      const isExact = rowData.isExactDistance ? '' : '*';
      return (
        <span className="body-bold text-text-100">
          {distanceInKiloMeters?.toFixed(1)}
          {isExact}
        </span>
      );
    },
  },
  {
    attributeName: 'targetDate',
    name: 'Срок',
    renderColumn: (column) => {
      return (
        <span className="flex w-max items-center gap-1">
          <IconLightning className="text-[#FF992C]" />{' '}
          {format(new Date(column as string), 'dd.MM.yyyy')}
        </span>
      );
    },
  },
];

export const styles = {
  label: 'text-text-50 callout-normal',
  value: '!text-text-100 body-paragraphs-normal',
};
