import { AppGalleryIcon, AppStoreIcon, BonusIcon, GooglePlayIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC } from 'react';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';

export const ShowAd: FC = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="mx-4 flex flex-col items-center gap-8 sm:mx-0">
      <div className="text-center">
        <BonusIcon />
      </div>
      <p className="body-normal !text-text-100">
        Мы рады сообщить, что наше мобильное приложение теперь доступно для скачивания любым удобным
        для вас способом. Вы можете найти его в App Store, в Google Play или AppGallery. Скачайте
        наше приложение уже сегодня и наслаждайтесь всеми его преимуществами!
      </p>
      <div className="text-center">
        <a
          href={'https://apps.apple.com/us/app/maykorpro/id6443843177'}
          target="blank"
          className="ml-1 cursor-pointer text-primary-100"
        >
          <AppStoreIcon />
        </a>
        <a
          href={'https://play.google.com/store/apps/details?id=com.maykorpro&pli=1'}
          target="blank"
          className="ml-1 cursor-pointer text-primary-100"
        >
          <GooglePlayIcon />
        </a>

        <a
          // href={'https://appgallery.huawei.com/#/app/110239723'}
          href={'https://appgallery.huawei.com/#/app/C110239723'}
          target="blank"
          className="ml-1 cursor-pointer text-primary-100"
        >
          <AppGalleryIcon />
        </a>
      </div>
      <Button variant={ButtonVariant.OUTLINE} onClick={() => dispatch(modalActions.closeModal())}>
        Закрыть
      </Button>
    </div>
  );
};
