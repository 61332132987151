import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import { format } from 'date-fns';
import { CalendarIcon } from 'assets';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import useMediaQuery from 'hooks/useMediaQuery';
// import './styles.css';
import { editRuLocale } from 'utils/helpers';

interface DatePickerProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ranges: Range;
  onSetParentState: (range: Range) => void;
}

// https://github.com/hypeserver/react-date-range/issues/330#issuecomment-802601417
export const initialValueRange = {
  startDate: undefined,
  // endDate: new Date(''),
  endDate: undefined,
  key: 'selection',
};

const DatePicker = memo(({ setOpen, ranges, onSetParentState }: DatePickerProps) => {
  const isPageMaxWidth = useMediaQuery('(max-width: 420px)');
  const ruLocale = editRuLocale();

  const onChangeValueHandler = (selection: Range) => {
    onSetParentState(selection);
  };

  const onRangeFocusHandler = (matrix: number[]) => {
    if (!isPageMaxWidth && matrix.every((m) => m === 0)) {
      setOpen(() => false);
    }
  };

  const onCloseHandler = () => {
    setOpen(() => false);
  };

  const onSaveHandler = () => {
    setOpen(() => false);
  };

  return (
    <>
      <div className="flex justify-between bg-teal p-4">
        <button type="button">
          <CloseIcon onClick={onCloseHandler} className="self-start text-white" />
        </button>
        <button type="button" className="text-base text-white" onClick={onSaveHandler}>
          Сохранить
        </button>
      </div>

      <p className="bg-teal px-4 text-white">Выбранный диапазон</p>
      <DateRange
        months={isPageMaxWidth ? 4 : 1}
        editableDateInputs={false}
        onChange={(item: RangeKeyDict) => onChangeValueHandler(item.selection)}
        onRangeFocusChange={onRangeFocusHandler}
        moveRangeOnFirstSelection={false}
        ranges={[ranges]}
        direction="vertical"
        scroll={{ enabled: false, calendarHeight: 220 }}
        locale={ruLocale}
        dateDisplayFormat="dd.MM.yyyy"
        startDatePlaceholder="дд.мм.гггг"
        endDatePlaceholder="дд.мм.гггг"
        weekdayDisplayFormat="EEEEEE"
        rangeColors={['#00c8c8', '#ccf4f4', '#00c8c8']}
        // rangeColors={
        //   ranges.startDate && ranges.endDate
        //     ? ['#00c8c8', '#ccf4f4', '#00c8c8']
        //     : ['#00c8c8', '#ccf4f4', '#00c8c8']
        // }
      />
    </>
  );
});

DatePicker.displayName = 'DatePicker';

const DatePickerWrapper = memo(({ setOpen, ranges, onSetParentState }: DatePickerProps) => {
  const isPageWide = useMediaQuery('(max-width: 420px)');

  return isPageWide ? (
    ReactDOM.createPortal(
      <div className="absolute top-0 left-0 right-0 z-30 ml-auto mr-auto w-full max-w-[420px] shadow-lg">
        <DatePicker setOpen={setOpen} ranges={ranges} onSetParentState={onSetParentState} />
      </div>,
      document.body
    )
  ) : (
    <div className="absolute left-0 z-30 w-[300px] shadow-lg">
      <DatePicker setOpen={setOpen} ranges={ranges} onSetParentState={onSetParentState} />
    </div>
  );
});

DatePickerWrapper.displayName = 'DatePickerWrapper';

interface DateRangePickerProps {
  placeholder: string;
  onChangeValue: (value: Range) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  value: Range;
  canClear?: boolean;
}

export const DateRangePicker = ({
  placeholder,
  onChangeValue,
  open,
  setOpen,
  value,
  canClear,
}: DateRangePickerProps) => {
  return (
    <>
      <div
        className="relative mb-3 flex w-full cursor-pointer justify-between border-b border-gray p-2"
        onClick={() => setOpen((prev) => !prev)}
      >
        {value.startDate && value.endDate ? (
          <div className="flex gap-8 text-base font-semibold">
            <span> {format(value.startDate, 'dd.MM.yyyy')}</span>
            <span> {format(value.endDate, 'dd.MM.yyyy')}</span>
          </div>
        ) : (
          <span className="text-base text-gray-medium">{placeholder}</span>
        )}
        {canClear && (
          <CloseIcon
            className="absolute right-[36px] top-[10px] h-5 w-5 p-1 text-gray-dark"
            onClick={(e) => {
              e.stopPropagation();
              onChangeValue(initialValueRange);
            }}
          />
        )}
        <img src={CalendarIcon} alt="выбрать дату" />
      </div>
      {open ? (
        <DatePickerWrapper setOpen={setOpen} ranges={value} onSetParentState={onChangeValue} />
      ) : null}
    </>
  );
};
