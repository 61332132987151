import { FC } from 'react';
import { CheckboxProps } from './types';
import classNames from 'classnames';
import { ValidationError } from '../ValidationError';
// import './styles.css';
import useMediaQuery from 'hooks/useMediaQuery';
import { IS_SMALL_DEVICE } from 'utils/settings';

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  className,
  title,
  errorMessage = '',
  onChange,
  ...rest
}) => {
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  return (
    <div className={classNames(`flex flex-col sm:ml-0`, className)}>
      <div className="flex items-center">
        <input
          className={classNames(
            `round-checkbox h-5 w-5 cursor-pointer !rounded-lg accent-primary-60 sm:rounded`,
            className
          )}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          {...rest}
        />
        {/* {isSmall ? (
          <CheckBoxOvalIcon
            className={classNames('rounded-full border border-light-50', {
              'text-primary-60': checked,
              'text-white': !checked,
            })}
          />
        ) : (
          <input
            className={classNames(
              `round-checkbox h-5 w-5 cursor-pointer !rounded-lg accent-primary-60 sm:rounded`,
              className
            )}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            {...rest}
          />
        )} */}

        <span className="ml-3">{title && title}</span>
      </div>
      <ValidationError errorMessage={errorMessage} />
    </div>
  );
};
