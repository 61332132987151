import { EExecutorSpecialization } from 'enums/executorSpecialization';
import * as yup from 'yup';

export const step4Schema = yup.object().shape({
  executorSpecializations: yup
    .array()
    .min(1, 'Область специализации должна быть заполнена')
    .required('Область специализации должна быть заполнена'),
  acceptsOffer: yup.bool().oneOf([true], 'Необходимо принять оферту и пользовательское соглашение'),
});

export const step4SchemaWithCities = yup.object().shape({
  serviceCities: yup
    .array()
    .min(1, 'Города обслуживания должны быть заполнены')
    .required('Область специализации должна быть заполнена'),
  executorSpecializations: yup
    .array()
    .min(1, 'Область специализации должна быть заполнена')
    .required('Область специализации должна быть заполнена'),
  acceptsOffer: yup.bool().oneOf([true], 'Необходимо принять оферту и пользовательское соглашение'),
});

export const specsData = [
  { value: EExecutorSpecialization.ITEngineer, label: 'ИТ-инженер', disabled: false },
  { value: EExecutorSpecialization.InstallationWork, label: 'Монтажные работы', disabled: false },
  { value: EExecutorSpecialization.Delivery, label: 'Доставка', disabled: false },
  { value: EExecutorSpecialization.Cleaning, label: 'Клининг', disabled: false },
  // { value: EExecutorSpecialization.Agent, label: 'Агент', disabled: false },
];
