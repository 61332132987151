import { ReactComponent as CheckAllTeal } from 'assets/check-all-teal.svg';
import { ReactComponent as DropdownArrowIcon } from 'assets/dropdown-arrow.svg';
import { ChatIcon, EmptyChatIcon, FileDownloadIcon } from 'assets/icons';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { memo, useEffect, useRef, useState } from 'react';

import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { ApplicationType } from 'enums/applicationType';
import { ChatEntityType } from 'enums/chatEntityType';
import { chatUserGroupNameById } from 'enums/chatUserGroup';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { getProfileID } from 'reduxStore/reducers/ProfileSlice/selectors/getProfileID';
import { getTimezone } from 'reduxStore/reducers/ProfileSlice/selectors/getTimezone';
import { ChatMembership, ChatMessage, Chats, User } from 'types';
import { editChatRuLocale, getDateWithTimezone } from 'utils/helpers';
import { IS_SMALL_DEVICE } from 'utils/settings';

interface ChatProps {
  applicationID: ApplicationType;
  entityID: number; // ИД сущности (заявка, анкета...)
  entityType: ChatEntityType;
  isMessageCanSent: boolean;
}

interface ChatState {
  chats: Chats | null;
  messages: ChatMessage[];
  members: ChatMembership[];
  lastMessage: ChatMessage | null;
}

const Chat = ({ entityType, entityID, applicationID, isMessageCanSent }: ChatProps) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<ChatState>({
    chats: null,
    messages: [],
    members: [],
    lastMessage: null,
  });
  const [notViewedMessages, setNotViewedMessages] = useState<number>(0);
  const textAreaValue = useRef('');
  const dispatch = useAppDispatch();
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  const timeZone = useAppSelector(getTimezone);
  const currentUserId = useAppSelector(getProfileID);

  const ruLocale = editChatRuLocale();

  useEffect(() => {
    const chatMessages = document.getElementsByClassName('message');
    const container = document.getElementById('message-container');
    const lastMessage = chatMessages[chatMessages.length - 1];
    if (container && lastMessage) {
      container.scrollIntoView({
        block: 'end',
        behavior: 'auto',
      });
    }
  });

  // Метод для пометки сообщения "прочитанным"
  const chatViewMessage = async (chatId: number, messageId: number) => {
    const viewMessageResponse = await request<Chats>(
      endpoints.CHAT_MODERATION_VIEW_MESSAGE.type,
      endpoints.CHAT_MODERATION_VIEW_MESSAGE.url(chatId, messageId)
    );
    if (viewMessageResponse.status === 200) {
      setAll(viewMessageResponse.data);
    }
  };

  useEffect(() => {
    request<Chats>(
      endpoints.CHAT_MODERATION_GET.type,
      endpoints.CHAT_MODERATION_GET.url(applicationID, entityID, entityType)
    ).then((chatData) => {
      if (chatData.status === 200) {
        setAll(chatData.data);
      }
    });
  }, [applicationID, entityID, entityType]);

  useEffect(() => {
    const { lastMessage } = state;
    const chatMessages = document.getElementsByClassName('message');
    const lastMessageDOM = chatMessages[chatMessages.length - 1];
    let enabled = true;
    const observer = new IntersectionObserver(
      () => {
        if (notViewedMessages > 0 && enabled) {
          lastMessage && chatViewMessage(lastMessage.chatId, lastMessage.id);
          enabled = false;
        }
      },
      {
        root: null,
        threshold: 1,
      }
    );
    if (lastMessageDOM) {
      observer.observe(lastMessageDOM);
    }
    return () => {
      observer.disconnect();
    };
  }, [open]);

  useEffect(() => {
    if (state.chats && currentUserId) {
      const index = state.members.findIndex((member) => member.userId === +currentUserId);
      if (index > -1) {
        const chatMember = state.members[index];
        setNotViewedMessages(chatMember.notViewedMessages);
      } else {
        setNotViewedMessages(0);
      }
    }
  }, [state.chats, state.members, currentUserId]);

  const UserAvatar = ({ user, showAvatar }: { user: User | null; showAvatar: boolean }) => {
    if (!user || !showAvatar) {
      return null;
    }
    const initials = user.lastName.charAt(0) + user.firstName.charAt(0);
    return (
      <span className="self-start rounded-full bg-primary-60 px-[10px] py-2 text-base text-white">
        {initials.toUpperCase()}
      </span>
    );
  };

  const Message = ({
    userId,
    content,
    created,
    isSystem,
  }: {
    userId: number;
    content: string;
    created: string;
    isSystem: boolean;
  }) => {
    const { members } = state;
    const messageClasses = {
      currentUser: {
        wrapper: 'justify-end',
        bg: 'bg-primary-60 rounded-t-lg rounded-bl-lg',
        time: 'justify-end',
        text: 'text-white',
      },
      anotherUser: {
        wrapper: 'gap-2',
        bg: 'bg-gray-light rounded-t-lg rounded-br-lg',
        time: 'justify-start',
        text: 'text-text-100',
      },
      systemUser: {
        wrapper: 'justify-center',
        bg: 'bg-primary-60 rounded-lg',
        time: 'justify-end',
        text: 'text-white',
      },
    };
    // todo Нужен рефакторинг
    let showAvatar,
      isCurrentUserMessage = false,
      user = null,
      chatMember = null,
      userGroupName = null;
    if (isSystem) {
      showAvatar = false;
    } else {
      const index = members.findIndex((member) => member.userId === userId);
      chatMember = members[index];
      user = chatMember.user;
      isCurrentUserMessage = !!userId && !!currentUserId && +currentUserId === userId;
      showAvatar = !isCurrentUserMessage;
      userGroupName = chatUserGroupNameById[chatMember.userGroup] || '';
    }

    const bg = isSystem
      ? messageClasses.systemUser.bg
      : isCurrentUserMessage
      ? messageClasses.currentUser.bg
      : messageClasses.anotherUser.bg;
    const wrapper = isSystem
      ? messageClasses.systemUser.wrapper
      : isCurrentUserMessage
      ? messageClasses.currentUser.wrapper
      : messageClasses.anotherUser.wrapper;
    const time = isSystem
      ? messageClasses.systemUser.time
      : isCurrentUserMessage
      ? messageClasses.currentUser.time
      : messageClasses.anotherUser.time;
    const text = isCurrentUserMessage
      ? messageClasses.currentUser.text
      : messageClasses.anotherUser.text;
    const formattedContent = content.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));

    return (
      <>
        <div className={`message flex ${wrapper}`}>
          <UserAvatar user={user} showAvatar={showAvatar} />
          <div className={`p-2 ${bg} flex w-60 gap-3`}>
            <p className={`leading-cz mb-1 flex-1 break-all ${text} `}>
              {isSystem && <span className="mb-1 block text-teal">Система</span>}
              {!isSystem && user && entityType === ChatEntityType.OrderChat && (
                <span className="mb-1 block">{`${userGroupName} (${user.firstName})`}</span>
              )}
              {!isSystem && user && entityType === ChatEntityType.ModerationRequestChat && (
                <span className="mb-1 block">{user.firstName}</span>
              )}
              {formattedContent}
            </p>
            <span
              className={`flex ${time} leading-cz callout-paragraphs-normal items-end gap-0.5 ${text}`}
            >
              {getDateWithTimezone(created, timeZone, 'HH:mm')}
              {isCurrentUserMessage && <CheckAllTeal />}
            </span>
          </div>
        </div>
      </>
    );
  };

  const sendChatMessage = async () => {
    if (!textAreaValue.current.trim().length) {
      return;
    }
    const messageCopy = textAreaValue.current.trim();
    const message = {
      content: textAreaValue.current.trim(),
      chatId: state.chats?.id,
      userId: currentUserId,
    };
    textAreaValue.current = '';
    const response = await request<Chats>(
      endpoints.CHAT_MODERATION_SEND_MESSAGE.type,
      endpoints.CHAT_MODERATION_SEND_MESSAGE.url(),
      {
        data: message,
      }
    );
    if (response.status === 200) {
      setAll(response.data);
    } else {
      // В случае ошибки возвращаем текст сообщения
      textAreaValue.current = messageCopy;
    }
  };

  function setAll(entity: Chats | null) {
    if (!entity) {
      setState({
        chats: null,
        messages: [],
        members: [],
        lastMessage: null,
      });
    } else {
      setState({
        chats: entity,
        messages: entity.messages,
        members: entity.memberships,
        lastMessage: entity.messages[entity.messages.length - 1],
      });
    }
  }

  const OpenedChat = () => {
    let lastDate = '';
    const { chats } = state;
    const [mobileChats, setMobileChats] = useState(chats);
    const mobileTextAreaValue = useRef<HTMLTextAreaElement>(null);

    const sendMChatMessage = async () => {
      if (mobileTextAreaValue.current) {
        if (mobileTextAreaValue.current.value.trim().length === 0) {
          return;
        }
      }
      const messageCopy = mobileTextAreaValue.current;
      let message;
      if (mobileTextAreaValue.current) {
        message = {
          content: mobileTextAreaValue.current.value,
          chatId: state.chats?.id,
          userId: currentUserId,
        };
      }
      if (mobileTextAreaValue.current) {
        mobileTextAreaValue.current.value = '';
      }
      const response = await request<Chats>(
        endpoints.CHAT_MODERATION_SEND_MESSAGE.type,
        endpoints.CHAT_MODERATION_SEND_MESSAGE.url(),
        {
          data: message,
        }
      );
      if (response.status === 200) {
        setAll(response.data);
        setMobileChats(response.data);
      } else {
        // В случае ошибки возвращаем текст сообщения
        if (mobileTextAreaValue.current && messageCopy) {
          mobileTextAreaValue.current.value = messageCopy.value;
        }
      }
    };

    return (
      <div className="relative h-auto w-screen max-w-[640px] self-end rounded-t-xl bg-white sm:w-full sm:px-4 sm:shadow-chatPanel md:m-0">
        {isSmall ? (
          <>
            {mobileChats &&
            mobileChats.messages.length > 0 &&
            mobileChats.memberships.length > 0 ? (
              <div className="h-full w-full overflow-x-hidden px-4 pt-2 pb-4 sm:px-2">
                <div className="flex flex-col gap-2 overflow-x-auto" id="message-container">
                  {mobileChats.messages.map(({ created, userId, content, isSystem }) => {
                    const date = format(new Date(created), 'dd.MM.yyyy');
                    let showDate;
                    if (!lastDate || lastDate !== date) {
                      lastDate = date;
                      showDate = true;
                    } else {
                      showDate = false;
                    }
                    return (
                      <div key={created}>
                        {showDate && (
                          <div className="leading-cz relative my-4 text-xs">
                            <span className="absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 rounded-3xl bg-light-30 py-1 px-3 text-text-100">
                              {format(new Date(created), 'd MMMM, yyyy', { locale: ru })}
                            </span>
                            <hr className="text-white" />
                          </div>
                        )}
                        <Message
                          isSystem={isSystem}
                          userId={userId}
                          content={content}
                          created={created}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="h-auto min-h-[90vh] overflow-x-hidden px-2 pt-2 pb-4 sm:h-96">
                <div
                  className="flex min-h-[89vh] flex-col items-center justify-center"
                  id="message-container"
                >
                  <EmptyChatIcon />
                  <h3 className="headline-bold text-text-100">Привет, это - чат!</h3>
                </div>
              </div>
            )}
            {isMessageCanSent && (
              <div className="relative flex items-center border-t border-gray">
                <textarea
                  className="leading-cz callout-normal my-1 ml-1 mr-12 w-full resize-none overflow-auto rounded-xl bg-light-30 p-3 pr-10 text-xs outline-none"
                  autoFocus
                  rows={1}
                  ref={mobileTextAreaValue}
                  placeholder="Сообщение"
                  onChange={(e) => {
                    if (mobileTextAreaValue.current) {
                      mobileTextAreaValue.current.value = e.target.value;
                    }
                  }}
                />
                <button
                  onClick={sendMChatMessage}
                  className="absolute right-4 top-4 cursor-pointer"
                  type="button"
                >
                  <FileDownloadIcon className="rotate-180 text-primary-60" />
                </button>
              </div>
            )}
          </>
        ) : (
          // DESKTOP
          <>
            {currentUserId ? (
              <>
                <div className="absolute -left-9 flex h-8 w-8 items-center justify-center rounded-2xl border text-text-50 hover:text-text-100">
                  <DropdownArrowIcon
                    className="h-4 w-4 cursor-pointer"
                    // className="h-4 w-4 cursor-pointer text-text-50 hover:text-text-100"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <div
                  className="headline-bold mb-2 flex cursor-pointer items-center gap-2 py-4 px-4 text-text-100"
                  onClick={() => setOpen(false)}
                >
                  <p>{chats?.name ?? 'Чат по заявке'}</p>
                </div>

                {chats && chats.messages.length > 0 && chats.memberships.length > 0 ? (
                  <div className="h-96 overflow-x-hidden px-2 pt-2 pb-4">
                    <div className="flex flex-col gap-2 overflow-x-auto" id="message-container">
                      {chats.messages.map(({ created, userId, content, isSystem }) => {
                        const date = format(new Date(created), 'dd.MM.yyyy');
                        let showDate;
                        if (!lastDate || lastDate !== date) {
                          lastDate = date;
                          showDate = true;
                        } else {
                          showDate = false;
                        }
                        return (
                          <div key={created}>
                            {showDate && (
                              <div className="leading-cz relative my-4 text-xs">
                                <span className="absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 rounded-3xl bg-light-30 py-1 px-3 text-text-100">
                                  {format(new Date(created), 'd MMMM, yyyy', { locale: ruLocale })}
                                </span>
                                <hr className="text-white" />
                              </div>
                            )}
                            <Message
                              isSystem={isSystem}
                              userId={userId}
                              content={content}
                              created={created}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="h-96 overflow-x-hidden px-2 pt-2 pb-4">
                    <div
                      className="flex h-full flex-col items-center justify-center"
                      id="message-container"
                    >
                      <EmptyChatIcon />
                      <h3 className="headline-bold text-text-100">Привет, это - чат!</h3>
                    </div>
                  </div>
                )}

                {isMessageCanSent && (
                  <div className="relative flex items-center border-t border-gray">
                    <textarea
                      className="leading-cz callout-normal my-1 ml-1 mr-12 w-full resize-none rounded-xl bg-light-30 p-3 pr-10 text-xs outline-none"
                      autoFocus
                      rows={1}
                      placeholder="Введите сообщение"
                      onChange={(e) => (textAreaValue.current = e.target.value)}
                    />
                    <button
                      onClick={sendChatMessage}
                      className="cursor-pointe absolute right-0 bottom-4"
                      type="button"
                    >
                      <FileDownloadIcon className="rotate-180 text-primary-60" />
                    </button>
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    );
  };

  const openChatHandler = () => {
    dispatch(
      modalActions.handleOpenModal({
        content: <OpenedChat />,
        options: {
          title: state?.chats?.name,
          modalClassName: 'h-auto min-h-screen',
        },
      })
    );
  };

  // Чат начат
  if (state.chats) {
    if (!open) {
      if (isSmall) {
        return <ChatPanel message={notViewedMessages} state={state} onClick={openChatHandler} />;
      } else {
        return (
          <ChatPanel
            message={notViewedMessages}
            chatName={state.chats.name}
            onClick={() => setOpen(true)}
          />
        );
      }
    } else {
      return <OpenedChat />;
    }
  } else {
    return (
      // <div className="mt-8 flex gap-2 bg-gray-light px-2 py-4">
      //   <ChatIcon className="text-gray-dark" />
      //   <span>Чат еще не создан</span>
      // </div>
      <></>
    );
  }
};

const ChatPanel = ({
  onClick,
  state,
  message = null,
}: {
  onClick: () => void;
  chatName?: string;
  state?: ChatState;
  message?: number | null;
}) => {
  return (
    <div
      className="w-fit cursor-pointer self-end rounded-tl-[20px] py-3 pl-3 pr-2 md:rounded-bl-[20px]"
      onClick={onClick}
    >
      <div className="flex items-center gap-2 text-white">
        {typeof message === 'number' ? (
          <>
            <ChatIcon className="relative text-primary-60 hover:text-primary-80 active:text-primary-100" />
            {message > 0 && (
              <span className="absolute right-3 top-5 rounded-xl bg-danger px-2 font-roboto text-[10px] leading-4">
                +{message}
              </span>
            )}
          </>
        ) : (
          <ChatIcon className="text-primary-60 hover:text-primary-80 active:text-primary-100" />
        )}
      </div>
    </div>
  );
};

export const ChatModeration = memo(Chat);
