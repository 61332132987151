import { UseFormSetError } from 'react-hook-form';
import { Education, WorkExperience } from 'types';
import { RequestHandler } from '../finalStep/consts';

export const checkExperience = (
  experience: WorkExperience | Education,
  index: number,
  requestHandler: RequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  let name = '';
  if ('isPrimary' in experience) {
    name = experience.isPrimary ? 'primaryEducation' : 'secondaryEducation';
  } else {
    name = 'workExperiences';
  }

  if (experience?.description && !experience?.files?.length) {
    const fullName = `${name}${index}files`;

    setError(fullName, {
      type: 'manual',
      message: 'Необходимо приложить 1 или более файлов',
    });
    toastErrors.push('Необходимо приложить 1 или более файлов');
  }

  if (!experience?.description && experience?.files?.length) {
    const fullName = `${name}${index}description`;
    setError(fullName, {
      type: 'manual',
      message: 'Описание должно быть заполнено',
    });
    toastErrors.push('Описание должно быть заполнено');
  }

  if (requestHandler === RequestHandler.MODERATION) {
    if (!experience?.description && !experience?.files?.length) {
      const fullName = `${name}${index}files`;

      setError(fullName, {
        type: 'manual',
        message: 'Необходимо приложить 1 или более файлов',
      });
      toastErrors.push('Необходимо приложить 1 или более файлов');
    }

    if (!experience?.description && !experience?.files?.length) {
      const fullName = `${name}${index}description`;
      setError(fullName, {
        type: 'manual',
        message: 'Описание должно быть заполнено',
      });
      toastErrors.push('Описание должно быть заполнено');
    }
  }
  return toastErrors;
};
