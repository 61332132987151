import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { RequestHandler } from './consts';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';

interface ButtonsProps {
  // status: ModerationRequestStatusId;
  status: number;
  parentId: number;
  submitHandler: (requestHandler?: RequestHandler) => Promise<void>;
  id?: number;
  isNavigateDisabled: boolean;
}

export const Buttons = ({
  status,
  parentId,
  submitHandler,
  id,
  isNavigateDisabled,
}: ButtonsProps) => {
  if (id) {
    if (status === ModerationRequestStatusId.Draft) {
      if (parentId > 0) {
        return (
          <div className="sm:mt-4 sm:mb-10">
            <Button
              className="w-full max-w-[640px] sm:mr-6 sm:max-w-[315px]"
              type="button"
              onClick={() => submitHandler(RequestHandler.MODERATION)}
              disabled={isNavigateDisabled}
            >
              ОТПРАВИТЬ НА МОДЕРАЦИЮ
            </Button>
          </div>
        );
      } else {
        return (
          <div className="sm:mt-4 sm:mb-10">
            <Button
              className="mr-0 mb-6 w-full max-w-[640px] sm:mr-6 sm:mb-0 sm:max-w-[315px]"
              type="button"
              onClick={() => submitHandler(RequestHandler.MODERATION)}
              disabled={isNavigateDisabled}
            >
              ОТПРАВИТЬ НА МОДЕРАЦИЮ
            </Button>
            <Button
              variant={ButtonVariant.SUCCESS}
              type="button"
              onClick={() => submitHandler(RequestHandler.DRAFT)}
              className="w-full max-w-[640px] sm:max-w-[275px]"
              disabled={isNavigateDisabled}
            >
              ОБНОВИТЬ ЧЕРНОВИК
            </Button>
          </div>
        );
      }
    } else if (status === ModerationRequestStatusId.ElaborationRequested) {
      return (
        <div className="mt-4 mb-10">
          <Button
            className="h-auto w-full max-w-[640px] whitespace-normal break-words sm:mr-6 sm:max-w-[400px]"
            type="button"
            onClick={() => submitHandler(RequestHandler.MODERATION)}
            disabled={isNavigateDisabled}
          >
            ПРЕДОСТАВИТЬ УТОЧНЕНИЕ ПО ЗАЯВКЕ
          </Button>
        </div>
      );
    } else if (
      [ModerationRequestStatusId.TrainingCompleted, ModerationRequestStatusId.Imported].includes(
        status
      )
    ) {
      return (
        <div className="mt-4 mb-10 w-full max-w-[640px] sm:max-w-[300px]">
          <Button
            type="button"
            onClick={() => submitHandler(RequestHandler.SECONDARY)}
            disabled={isNavigateDisabled}
          >
            ВНЕСТИ ИЗМЕНЕНИЯ
          </Button>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return (
      <div className="mt-4 mb-10 w-full max-w-[640px] sm:max-w-[300px]">
        <Button
          variant={ButtonVariant.SUCCESS}
          type="button"
          onClick={() => submitHandler(RequestHandler.DRAFT)}
          disabled={isNavigateDisabled}
        >
          СОХРАНИТЬ ЧЕРНОВИК
        </Button>
      </div>
    );
  }
};
