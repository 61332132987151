import { UseFormSetError } from 'react-hook-form';

export const checkСertificateElectricalSafetyScan = (
  certificateElectricalSafetyQuantity: number | null,
  certificateElectricalSafetyScan: OrderFile[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (!certificateElectricalSafetyQuantity && !certificateElectricalSafetyScan?.length) {
    return toastErrors;
  }
  if (certificateElectricalSafetyQuantity && !certificateElectricalSafetyScan?.length) {
    setError('certificateElectricalSafetyScan', {
      type: 'manual',
      message: 'Необходимо приложить не более 5 файлов',
    });
    toastErrors.push('Необходимо приложить не более 5 файлов');
  }
  if (!certificateElectricalSafetyQuantity && certificateElectricalSafetyScan?.length) {
    setError('certificateElectricalSafetyQuantity', {
      type: 'manual',
      message: 'Количество сотрудников не может быть равно 0 и превышать 9999',
    });
    toastErrors.push('Количество сотрудников не может быть равно 0 и превышать 9999');
  }
  return toastErrors;
};

export const checkСertificateWorkHeightScan = (
  certificateWorkHeightQuantity: number | null,
  certificateWorkHeightScan: OrderFile[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  // if (certificateWorkHeightScan?.length === 0) {
  //   setError('certificateWorkHeightScan', {
  //     type: 'manual',
  //     message: 'Необходимо приложить не более 5 файлов',
  //   });
  //   maskErrors++;
  // }
  if (!certificateWorkHeightQuantity && !certificateWorkHeightScan?.length) {
    return toastErrors;
  }
  if (certificateWorkHeightQuantity && !certificateWorkHeightScan?.length) {
    setError('certificateWorkHeightScan', {
      type: 'manual',
      message: 'Необходимо приложить не более 5 файлов',
    });
    toastErrors.push('Необходимо приложить не более 5 файлов');
  }
  if (!certificateWorkHeightQuantity && certificateWorkHeightScan?.length) {
    setError('certificateWorkHeightQuantity', {
      type: 'manual',
      message: 'Количество сотрудников не может быть равно 0 и превышать 9999',
    });
    toastErrors.push('Количество сотрудников не может быть равно 0 и превышать 9999');
  }
  return toastErrors;
};
