import { ConfirmPhoneIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { FieldInput } from 'components/redesign/FieldInput';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTimer } from 'hooks/useTimer';
import { FC, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { ProfileService } from 'services/ProfileService';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { InfoBlock } from 'ui/redesign/InfoBlock';
import { CodeRequestTimeout } from '../CodeRequestTimeout';
import { confirmCodeRules, smsTimeout } from './constants';
import { ChangePhoneForm, ChangePhoneNumberFormProps, ConfirmPhoneError } from './types';
import { InfoBlockVariant } from 'ui/redesign/InfoBlock/constants';
import { FieldTextarea } from 'components/redesign/FieldTextArea';

export const ChangePhoneNumberForm: FC<ChangePhoneNumberFormProps> = ({ currentPhoneNumber }) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isValid, errors },
    setValue,
    watch,
  } = useForm<ChangePhoneForm>({
    mode: 'onChange',
    defaultValues: {
      phoneNumber: currentPhoneNumber,
      confirmCode: '',
    },
  });
  const [isVerifying, setIsVerifying] = useState(false);
  const queryResultRef = useRef<ConfirmResponse | null>(null);
  const queryClient = useQueryClient();
  const [timer, resetTimer] = useTimer(0);
  const dispatch = useAppDispatch();

  const { mutateAsync: sendConfirmCode } = useMutation(
    ['sendConfirmCode'],
    () =>
      ProfileService.sendPhoneConfirmCode({
        newPhoneNumber: getValues('phoneNumber').replaceAll(' ', ''),
      }),
    {
      onSuccess: (data) => {
        if (data.data.isSuccess) {
          setIsVerifying(true);
          queryResultRef.current = data.data;
          resetTimer(smsTimeout);
        }
        queryResultRef.current = data.data;
      },
    }
  );
  const { mutateAsync: resendConfirmCode } = useMutation(
    ['sendConfirmCode'],
    () =>
      ProfileService.resendPhoneConfirmCode({
        phoneNumber: getValues('phoneNumber').replaceAll(' ', ''),
      }),
    {
      onSuccess: (data) => {
        setIsVerifying(true);
        queryResultRef.current = data.data;
        resetTimer(smsTimeout);
      },
    }
  );
  const { mutateAsync: changePhoneNumber } = useMutation(
    ['changePhoneNumber'],
    () =>
      ProfileService.changePhoneNumber({
        newPhoneNumber: getValues('phoneNumber').replaceAll(' ', ''),
        code: getValues('confirmCode'),
      }),
    {
      onSuccess: (data) => {
        if (data.data.isSuccess) {
          queryClient.invalidateQueries({ queryKey: ['getProfile'] });
          dispatch(modalActions.closeModal());
        } else {
          queryResultRef.current = data.data;
          setValue('confirmCode', '');
        }
      },
      onError: (error: AxiosError<ConfirmPhoneError>) => {
        if (error?.response) {
          queryResultRef.current = {
            isSuccess: false,
            message: error.response.data.errors.NewPhoneNumber[0],
          };
        }
      },
    }
  );

  const currentPhone = watch('phoneNumber').replace(/\D+/g, '');
  const profilePhone = currentPhoneNumber.replace(/\D+/g, '');
  const isPhoneEqual = currentPhone === profilePhone || currentPhone.length !== 11;

  const sendHandler: SubmitHandler<ChangePhoneForm> = async (data) => {
    await sendConfirmCode();
  };

  const clickHandler = async () => {
    await resendConfirmCode();
  };

  const confirmHandler: SubmitHandler<ChangePhoneForm> = async (data) => {
    await changePhoneNumber();
  };

  return (
    <div className="w-full p-4 sm:min-w-[600px] sm:p-0">
      <form onSubmit={handleSubmit(isVerifying ? confirmHandler : sendHandler)}>
        <div className="my-4 text-center">
          <ConfirmPhoneIcon />
        </div>
        {isVerifying ? (
          <div className="mt-2 mb-4 text-text-100">
            {queryResultRef.current?.isSuccess ? (
              <InfoBlock
                text={queryResultRef.current.message.split('.')[0]}
                description={queryResultRef.current.message.split('.')[1]}
                // className="flex h-24 flex-col"
              />
            ) : (
              queryResultRef.current && (
                <InfoBlock
                  text={queryResultRef.current.message.split('.')[0]}
                  description={queryResultRef.current.message.split('.')[1]}
                  variant={InfoBlockVariant.ERROR}
                  // className="flex h-24"
                />
              )
            )}
          </div>
        ) : (
          queryResultRef.current && (
            <InfoBlock
              text={queryResultRef.current.message.split('.')[0]}
              description={queryResultRef.current.message.split('.')[1]}
              variant={InfoBlockVariant.ERROR}
              className="mb-4"
            />
          )
        )}
        <FieldInput
          name="phoneNumber"
          control={control}
          label="Новый номер телефона"
          mask="+7 (999) 999-99-99"
          disabled={isVerifying}
        />
        {isVerifying ? (
          <>
            <div className="mt-4">
              {/* <FieldInput
                name="confirmCode"
                control={control}
                label="Код из смс"
                rules={isVerifying && confirmCodeRules}
                isClearable
                type="number"
              /> */}
              <FieldTextarea
                name="confirmCode"
                control={control}
                label="Код из смс"
                rules={isVerifying && confirmCodeRules}
                isClearable
                maxLength={6}
                // type="number"
              />
              {errors && <div className="text-danger">{errors['confirmCode']?.message}</div>}
              {timer > 0 && <CodeRequestTimeout timer={timer} />}
            </div>
            <div className="mt-6 flex flex-col-reverse sm:flex-row sm:justify-end">
              <Button
                disabled={timer > 0}
                variant={ButtonVariant.GHOST}
                type="button"
                onClick={clickHandler}
                className="body-paragraphs-normal mb-1 w-full max-w-[640px] sm:w-[270px]"
              >
                ОТПРАВИТЬ ПОВТОРНО
              </Button>
              <Button
                disabled={!isValid || isSubmitting}
                type="submit"
                className="body-paragraphs-bold mb-1 w-full max-w-[640px] sm:w-[240px]"
              >
                ПОДТВЕРДИТЬ КОД
              </Button>
            </div>
          </>
        ) : (
          <div className="mt-6 text-right">
            <Button
              disabled={isPhoneEqual || isSubmitting}
              type="submit"
              className="body-paragraphs-bold mb-1 w-full max-w-[640px] sm:w-[330px]"
            >
              ОТПРАВИТЬ СМС С КОДОМ
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};
