import { endpoints } from 'api/endpoints';
import { ReactComponent as DropdownArrowGrayIcon } from 'assets/dropdown-arrow-gray.svg';
import { FormFilterValues } from 'components/Receipts1S';
import { Payments } from 'components/Receipts1S/Payments';
import { months, receiptsFilterDefaultValues } from 'components/Receipts1S/const';
import { useFetchData } from 'hooks/useFetchData';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PaymentBySumm, User } from 'types';
import { Spinner } from 'ui/Spinner';

import FilterModal from 'components/Receipts1S/FilterModal';
import { useAppDispatch } from 'hooks/useAppDispatch';

export const ExecutorReceipts = () => {
  const [filters, setFilters] = useState<FormFilterValues>(receiptsFilterDefaultValues);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const {
    control: formFilterControl,
    handleSubmit: formFilterHandleSubmit,
    reset: formFilterReset,
    watch: formFilterwatch,
  } = useForm<FormFilterValues>({
    defaultValues: receiptsFilterDefaultValues,
  });

  const { data } = useFetchData<User>(endpoints.GET_PROFILE.url());
  const userId = data?.id;

  const formFilterHandleSubmitWatcher = useCallback(
    (data: FormFilterValues) => {
      setFilters(data);
    },
    [setFilters]
  );

  const {
    data: paymentOrds,
    isLoading: ordersIsLoading,
    isFetching,
    refetch,
  } = useFetchData<PaymentBySumm[]>(
    endpoints.GET_MY_EXECUTOR_RECEIPTS.url(filters.year?.value, filters.month?.value),
    endpoints.GET_MY_EXECUTOR_RECEIPTS.type,
    {},
    { enabled: !!userId }
  );

  const onShowFilters = () => {
    setOpen(true);
  };

  const onCloseFilters = () => {
    setOpen(false);
  };

  const submitHandler = () => {
    formFilterHandleSubmit((val) => {
      val.month.value === filters.month.value && val.year.value === filters.year.value
        ? refetch()
        : setFilters(val);
    })();
  };

  return (
    <div className="sm:sh-[75vh] flex flex-col">
      <div>
        <div className="my-4">
          <span className="callout-normal !text-text-50">Год:</span>
          <span
            className="flex max-w-[80px] cursor-pointer items-center justify-start gap-3 text-base font-semibold !text-primary-60"
            onClick={onShowFilters}
          >
            <>
              {filters.year.value}{' '}
              <DropdownArrowGrayIcon className="h-3 w-3 -rotate-90 text-[#D1D1D6]" />
            </>
          </span>
        </div>
        <div className="mb-4">
          <span className="callout-normal !text-text-50">Месяц:</span>
          <span
            className="flex max-w-[120px] cursor-pointer items-center justify-start gap-3 text-base font-semibold !text-primary-60"
            onClick={onShowFilters}
          >
            <>
              {filters.month.value && months[+filters.month.value - 1].label}{' '}
              <DropdownArrowGrayIcon className="h-3 w-3 -rotate-90 text-[#D1D1D6]" />
            </>
          </span>
        </div>
      </div>
      <div className="flex h-auto w-full flex-col md:h-full">
        {isFetching ? <Spinner /> : <Payments paymentOrders={paymentOrds} refetch={refetch} />}
      </div>
      <FilterModal
        control={formFilterControl}
        open={open}
        // onSubmit={formFilterHandleSubmit(formFilterHandleSubmitWatcher)}
        onSubmit={submitHandler}
        onReset={() => formFilterReset(receiptsFilterDefaultValues)}
        onClose={onCloseFilters}
        type="by-period"
        watch={formFilterwatch}
      />
    </div>
  );
};
