import { FormFilterValues } from 'components/Receipts1S';
import { Control, UseFormWatch } from 'react-hook-form';
import { OrderFile } from 'types';

export interface RecieptsByPeriodFormData {
  firstPartMonthFiles: OrderFile[];
  secondPartMonthFiles: OrderFile[];
}

export enum RecieptsByPeriodFormFieldNames {
  FirstPartMonthFiles = 'firstPartMonthFiles',
  SecondPartMonthFiles = 'secondPartMonthFiles',
}
export interface RecieptsByPeriodFormProps {
  control: Control<any>;
  onSubmit: () => void;
  className?: string;
  filters: FormFilterValues;
  watch: UseFormWatch<RecieptsByPeriodFormData>;
}

export interface RecieptsByPeriodProps {
  className?: string;
}
