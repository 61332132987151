import { ExecutorTypeEnum } from 'reduxStore/reducers/ProfileSlice/types';
import { SubcontractorType } from '../SubcontractorModeration/types';

export const stepCards = [
  { title: 'Шаг 1', description: 'Проверьте личные данные' },
  {
    title: 'Шаг 2',
    description: 'Отсканируйте паспорт. Поднесите его к камере телефона в развернутом виде',
  },
  {
    title: 'Шаг 3',
    description: 'Укажите банковские реквизиты',
  },
  { title: 'Шаг 4', description: 'Выберите регион. Укажите регион ведения деятельности' },
  { title: 'Шаг 5', description: 'Проверьте контактные данные' },
  {
    title: 'Шаг 6',
    description: 'Укажите образование и опыт работы',
  },
  {
    title: 'Шаг 7',
    description: 'Отсканируйте документы для допуска к работам',
  },
];

export const executorTypes = [
  {
    label: 'Самозанятый',
    value: ExecutorTypeEnum.Selfemployed,
  },
  {
    label: 'ИП',
    value: ExecutorTypeEnum.Individual,
  },
];

export const defaultFormValues = {
  id: 0,
  rowVersion: null,
  moderatedUserId: 0,
  recruiterId: 0,
  inn: null,
  bik: null,
  bankSettlement: null,
  mobilePhone: null,
  email: null,
  created: null,
  acceptsOffer: false,
  parentId: 0,
  birthDate: null,
  executorType: ExecutorTypeEnum.Selfemployed,
  status: 0,
  fullName: null,
  citizenship: 1,
  pyrusUrl: null,
  passport: {},
  executorPhotos: {
    files: [],
  },
  selfemployedCertificate: {
    files: [],
  },
  educations: null,
  workExperiences: [],
  serviceCities: [],
  executorSpecializations: [],
  individualCertificate: {
    ogrnip: null,
    individualName: null,
    files: [],
  },
  userRegistration: '',
  recruiterFullName: null,
  checkLists: {},
};
