import { EServiceAreaType } from 'enums/serviceAreaType';
import { ESubcontractorServiceAreaType } from 'enums/subcontractorServiceAreaType';
import {
  ServiceAreaIsCity,
  ServiceAreaIsFederal,
  ServiceAreaIsRegion,
} from 'pages/ModerationRequest/SubcontractorModeration';
import { RegionAndCity, ServiceArea } from '../finalStep/types';
import * as yup from 'yup';
import { OrganizationType } from 'enums/organizationType';

export const serviceAreasRadio = [
  {
    label: 'Федеральный округ',
    value: ESubcontractorServiceAreaType.FederalDistrict,
  },
  {
    label: 'Субъект Федерации',
    value: ESubcontractorServiceAreaType.Region,
  },
  {
    label: 'Населенный пункт',
    value: ESubcontractorServiceAreaType.City,
  },
];

export const getCityAreas = (cities: RegionAndCity[], id: number): ServiceArea[] => {
  if (!cities?.length) {
    return [];
  }
  return cities?.map((city) => ({
    id: 0,
    subcontractorId: id || 0,
    serviceAreaTypeId: EServiceAreaType.City,
    cityFiasId: city.value.fiasId,
    federalDistrictId: null,
    regionFiasId: null,
    objectFullName: city.label,
  }));
};

export const getRegionAreas = (regions: RegionAndCity[], id: number): ServiceArea[] => {
  return regions?.map((region) => ({
    id: 0,
    subcontractorId: id || 0,
    serviceAreaTypeId: EServiceAreaType.Region,
    regionFiasId: region.value.fiasId,
    cityFiasId: null,
    federalDistrictId: null,
    objectFullName: region.label,
  }));
};

export const getFederalAreas = (
  federals: (number | null | undefined)[],
  id: number
): ServiceArea[] => {
  return federals?.map((federal) => ({
    id: 0,
    subcontractorId: id || 0,
    serviceAreaTypeId: EServiceAreaType.FederalDistrict,
    federalDistrictId: federal,
    cityFiasId: null,
    regionFiasId: null,
    objectFullName: null,
  }));
};

export const getServiceAreas = (data: any): ServiceArea[] => {
  let areas: ServiceArea[] = [];
  switch (data.serviceAreaTypeId) {
    case EServiceAreaType.FederalDistrict:
      areas = getFederalAreas(data.serviceAreasF, data.serviceAreaTypeId);
      break;
    case EServiceAreaType.City:
      areas = getCityAreas(data.serviceAreasC, data.serviceAreaTypeId);
      break;
    case EServiceAreaType.Region:
      areas = getRegionAreas(data.serviceAreasR, data.serviceAreaTypeId);
      break;
    default:
      areas = [];
      break;
  }
  return areas;
};

export function isCity(area: ServiceArea): area is ServiceAreaIsCity {
  return 'cityFiasId' in area && !!area.cityFiasId;
}

export const isRegion = (area: ServiceArea): area is ServiceAreaIsRegion => {
  return 'regionFiasId' in area && !!area.regionFiasId;
};

export const isFederal = (area: ServiceArea): area is ServiceAreaIsFederal => {
  return 'federalDistrictId' in area && !!area.federalDistrictId;
};

export const step1LLCSchema = yup.object().shape({
  organizationName: yup
    .string()
    .typeError('Наименование Организации/ИП должно быть заполнено')
    .required('Наименование Организации/ИП должно быть заполнено'),
  egrulScan: yup.array().typeError('Необходимо приложить файл').min(1, 'Необходимо приложить файл'),
  // serviceAreas: yup
  //   .array()
  //   .typeError('Территория обслуживания должна быть заполнена')
  //   .min(1, 'Территория обслуживания должна быть заполнена'),
  // serviceAreas: yup
  //   .array().of(
  //     yup.object().shape()
  //   )
  //   .transform((value) => console.log(value))
  //   .typeError('Территория обслуживания должна быть заполнена'),
});

export const step1IndividualSchema = yup.object().shape({
  organizationName: yup
    .string()
    .typeError('Наименование Организации/ИП должно быть заполнено')
    .required('Наименование Организации/ИП должно быть заполнено'),
  egripScan: yup.array().typeError('Необходимо приложить файл').min(1, 'Необходимо приложить файл'),
  // serviceAreas: yup
  //   .array()
  //   .typeError('Территория обслуживания должна быть заполнена')
  //   .transform((value) => console.log(value))
  //   .min(1, 'Территория обслуживания должна быть заполнена'),
  // if (data.serviceAreas?.length === 0 || !data.serviceAreas) {
  //   setError('serviceAreas', {
  //     type: 'manual',
  //     message: 'Территория обслуживания должна быть заполнена',
  //   });
  //   maskErrors++;
  // }
});

export const getFinalSchema = (actualSubcontractorType: OrganizationType) => {
  const schema =
    actualSubcontractorType === OrganizationType.Individual
      ? step1IndividualSchema
      : step1LLCSchema;
  return schema;
};
