import { ExecutorTypeEnum } from 'enums/executorType';
import { FieldValues } from 'react-hook-form';
import { ModerationRequest } from 'services/ModerationRequestService/types';

export const transformData = (data: FieldValues) => {
  const extracted = data?.serviceCities?.map((item: any) => {
    if ('isMarked' in item.value) {
      return { fiasId: item.value.suggestion.data.fias_id, objectFullName: item.label };
    }
    return item.value;
  });

  const transformed: ModerationRequest = {
    acceptsOffer: data.acceptsOffer,
    bankSettlement: data.bankSettlement,
    bik: data.bik,
    birthDate: data.birthDate,
    checkLists: data.checkLists,
    created: data.created,
    email: data.email,
    executorSpecializations: data.executorSpecializations,
    executorType: data.executorType,
    id: data.id,
    individualCertificate: data.individualCertificate,
    inn: data.inn,
    mobilePhone: data.mobilePhone,
    moderatedUserId: data.moderatedUserId,
    parentId: data.parentId,
    pyrusUrl: data.pyrusUrl,
    recruiterFullName: data.recruiterFullName,
    recruiterId: data.recruiterId,
    rowVersion: data.rowVersion,
    status: data.status,
    userRegistration: data.userRegistration,
    citizenship: data.citizenship.value,
    selfemployedCertificate: {
      files: data.selfemployedCertificate,
    },
    certificateElectricalSafety: {
      files: data.certificateElectricalSafety,
    },
    certificateWorkHeight: {
      files: data.certificateWorkHeight,
    },
    passport: {
      series: data.series,
      number: data.number,
      dateOfIssue: data.dateOfIssue,
      issuedBy: data.issuedBy,
      unitCode: data.unitCode,
      files: data.files,
      registrationAddress: data.registrationAddress,
      birthPlace: data.birthPlace,
    },
    executorPhotos: {
      files: data.executorPhotos,
    },
    fullName: `${data.lastName} ${data.firstName} ${data.middleName}`,
    educations: [...data.primaryEducation, ...data.secondaryEducation],
    serviceCities: extracted,
  } as ModerationRequest;
  return transformed;
};

export const trimDescription = (arr: any) => {
  for (let i = 0; i < arr.length; i++) {
    arr[i].description = arr[i].description.trim();
  }

  return arr;
};
