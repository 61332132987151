import { ArrowBack } from 'assets/icons';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { NewPaymentOrder, PaymentOrderEnum } from 'types';
import UploadReceiptInput from 'ui/UploadInput/UploadReceiptInput';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { formatAmount, getDateWithTimezone } from 'utils/helpers';
import { StatusIcon, getReason } from './const';

interface SelectedPaymentProps {
  handleClose: () => void;
  setSelectedPayment: (selectedPayment: NewPaymentOrder) => void;
  selectedPayment: NewPaymentOrder;
  uploadReceipt: () => void;
}

export const SelectedPayment: FC<SelectedPaymentProps> = ({
  handleClose,
  selectedPayment,
  setSelectedPayment,
  uploadReceipt,
}) => {
  return (
    <div className="absolute top-6 z-40 h-full w-full overflow-x-hidden bg-white pr-6 sm:top-5 lg:top-5">
      <div className="flex items-center gap-4 pb-6">
        <ArrowBack className="cursor-pointer lg:hidden" onClick={handleClose} />
        <span className="title2-medium sm:title2-bold lg:large-title2-bold">Чеки</span>
      </div>
      <div>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          className="max-w-[700px] gap-4"
        >
          <div className="callout-normal sm:body-normal rounded-lg border border-light-50 py-4 shadow-md">
            <div className="mx-4 flex justify-between border-b border-light-50 py-4">
              <div className=" text-text-50">Сумма</div>
              <div className="headline-bold sm:title2-bold">
                {formatAmount(selectedPayment.amount as number, { minimumFractionDigits: 2 })} руб.
              </div>
            </div>
            <div className="mx-4 flex flex-col justify-between border-b border-light-50 py-4">
              <div className="headline-bold sm:title2-bold mb-2 py-2">Получатель</div>
              <div className="flex flex-row justify-between">
                <div className="gap-4 text-text-50">ФИО</div>
                <div className="body-normal sm:headline-normal max-w-[50%] break-words text-right">
                  {selectedPayment.fullName}
                </div>
              </div>
            </div>
            <div className="mx-4 mb-2  flex flex-col justify-between border-b border-light-50 py-4">
              <div className="headline-bold sm:title2-bold mb-2 py-2">Детали</div>
              <div className="flex flex-row justify-between">
                <div className=" text-text-50">Дата</div>
                <div className="body-normal sm:headline-normal">
                  {getDateWithTimezone(selectedPayment.date, 0, 'dd.MM.yyyy')}
                </div>
              </div>
            </div>
            <div className="mx-4 mb-2  flex justify-between border-b border-light-50 py-4">
              <div className=" text-text-50">Статус</div>
              <div className="body-normal sm:headline-normal">
                <StatusIcon status={selectedPayment.status} />
              </div>
            </div>
            {selectedPayment.refuseReasons && selectedPayment.refuseReasons?.length > 0 && (
              <>
                <div className="mx-4 flex justify-between border-b border-light-50 py-4">
                  <div className=" text-text-50">Причина отказа</div>
                  <div className="flex flex-col items-end">
                    {selectedPayment.refuseReasons?.map((reason, index) => {
                      return <div key={index}>{getReason(reason.refuseReason)}</div>;
                    })}
                  </div>
                </div>

                <div className="mx-4 flex justify-between gap-4 border-b border-light-50 py-4">
                  <div className=" text-text-50">Комментарий к отказу</div>
                  <div className="flex flex-col items-end">
                    {selectedPayment.refuseReasons?.map((reason, index) => {
                      return <div key={index}>{reason.refuseComment}</div>;
                    })}
                  </div>
                </div>
              </>
            )}
            <div className="mx-4 mb-2 flex justify-between gap-4 border-b border-light-50 py-4">
              <div className=" text-text-50">Назначение</div>
              <div className="body-normal sm:headline-normal text-text-100">
                {selectedPayment.description}
              </div>
            </div>
            <div className="mx-4 flex justify-between border-b border-light-50 py-4">
              <div className="gap-4  text-text-50">Номер платежного поручения</div>
              <div className="body-normal sm:headline-normal text-text-100">
                {selectedPayment.number}
              </div>
            </div>
            <div className="mx-4 flex flex-col justify-between py-4">
              <div className="headline-bold sm:title2-bold mb-2 py-2">Квитанция</div>
              <UploadReceiptInput
                status={selectedPayment.status}
                paymentOrderId={selectedPayment.id}
                receiptFiles={selectedPayment.files ? selectedPayment.files : []}
                disabled={false}
                onChange={(files) => setSelectedPayment({ ...selectedPayment, files })}
                // isHidePreviewFileButton
              />
            </div>
          </div>
        </motion.div>
        {PaymentOrderEnum['Чек принят'] === selectedPayment.status ||
        PaymentOrderEnum['Ожидает проверки'] === selectedPayment.status ? (
          <div className="my-10 flex justify-start gap-6">
            <Button
              variant={ButtonVariant.OUTLINE}
              onClick={handleClose}
              className="btn-ghost mb-6 w-full max-w-[640px] self-center font-medium sm:max-w-[360px]"
            >
              ВЕРНУТЬСЯ К ПЛАТЕЖАМ
            </Button>
          </div>
        ) : (
          <div className="my-4 flex max-w-[700px] flex-col-reverse justify-between gap-1 pb-14 sm:my-10 sm:flex-row sm:gap-6 md:pb-0">
            <Button
              variant={ButtonVariant.OUTLINE}
              onClick={handleClose}
              className="btn-ghost !font-xs mb-6 w-full max-w-[640px] self-center font-medium sm:max-w-[288px]"
            >
              ВЕРНУТЬСЯ К ПЛАТЕЖАМ
            </Button>
            <Button
              onClick={uploadReceipt}
              variant={ButtonVariant.SUCCESS}
              className="btn-accent mb-6 w-full max-w-[640px] self-center font-medium sm:max-w-[288px]"
              disabled={!(selectedPayment?.files && selectedPayment?.files.length > 0)}
            >
              СОХРАНИТЬ ЧЕКИ
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
