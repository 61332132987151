import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { useCallback, useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import { Controller, useForm } from 'react-hook-form';
import { MultiValue, SingleValue } from 'react-select';

import { initialValueRange } from 'components/DateRangePicker';
import { OptionType } from 'ui/Select';

import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { FieldInput } from 'components/redesign/FieldInput';
import { FieldSelect } from 'components/redesign/FieldSelect';
import { useFetchData } from 'hooks/useFetchData';
import useMediaQuery from 'hooks/useMediaQuery';
import { usePrevious } from 'hooks/usePrevious';
import { EntityShort, Settlement } from 'types';
import { Button } from 'ui/redesign/Button';
import { DateRangePicker } from 'ui/redesign/DateRangePicker';
import { transformToSelectOptions } from 'utils/helpers';
import { IS_SMALL_DEVICE, SMALL_DEVICE_MEDIA_QUERY } from 'utils/settings';
import { OrderTabType } from './types';
import { SettlementsSelect } from 'components/redesign/SettlementsSelect';

const FilterIcon = () => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.96377 5L8.80874 11.6618C8.93304 11.8327 9 12.0387 9 12.25V14.382L11 15.382V12.25C11 12.0387 11.067 11.8327 11.1913 11.6618L16.0362 5H3.96377ZM19 0C19.5523 0 20 0.447715 20 1V4C20 4.55228 19.5523 5 19 5H18.508L13 12.5752V17C13 17.7434 12.2177 18.2269 11.5528 17.8944L7.55279 15.8944C7.214 15.725 7 15.3788 7 15V12.5752L1.491 5H1C0.447715 5 0 4.55228 0 4V1C0 0.447715 0.447715 0 1 0H19ZM18 2H2V3H18V2Z"
      fill="#848484"
    />
  </svg>
);

export interface OrderFilters {
  cities: any[] | null;
  customId: string;
  create_date: Range;
  clients: any[] | null;
  areDependentObjectsIncluded: boolean | null;
}

export interface RequestFilters {
  cityFiasIds: number[] | null;
  customId: string;
  create_date: Range;
  clientIds: number[] | null;
  areDependentObjectsIncluded: boolean | null;
}

type Filter = keyof OrderFilters;

interface FilterProps {
  onFilter: (filters: OrderFilters) => void;
  orderTabType: OrderTabType;
}

export const initialFilters: OrderFilters = {
  cities: [],
  customId: '',
  create_date: {},
  clients: [],
  areDependentObjectsIncluded: false,
};

type FormFilterValues = {
  cityFiasIds: OptionType[] | null;
  customId: string;
  create_date: Range;
  clientIds: OptionType[] | null;
};

interface CommonSettlementsResponse {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  pagedList: Settlement[];
  totalCount: number;
  totalPages: number;
}

export const Filters = ({ onFilter, orderTabType }: FilterProps) => {
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<OrderFilters>(initialFilters);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [cities, setCities] = useState<Settlement[]>([]);
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  const { control, handleSubmit, reset } = useForm<FormFilterValues>({
    defaultValues: {
      cityFiasIds: [],
      customId: '',
      create_date: initialValueRange,
      clientIds: [],
    },
  });

  const isPageMaxWidth = useMediaQuery(SMALL_DEVICE_MEDIA_QUERY);

  const { data: projects } = useFetchData<EntityShort[]>(endpoints.GET_LEGAL_ENTITIES_SHORT.url());

  const prevOrderTabType = usePrevious(orderTabType);

  useEffect(() => {
    getCities('');
  }, []);

  const getCities = async (template: string) => {
    const response = await request<CommonSettlementsResponse>(
      endpoints.GET_COMMON_SETTLEMENTS.type,
      endpoints.GET_COMMON_SETTLEMENTS.url(template)
    );
    if (response.status === 200) {
      setCities(response.data.pagedList);
    }
  };

  const activeFiltersLength = Object.values(filters).filter(
    (item) => item && ((Boolean(item) && item.length) || Object.keys(item).length || item > 0)
  ).length;

  const onShowFilters = () => {
    document.body.classList.add('fixed-container');
    setOpen(true);
  };

  const onCloseFilters = () => {
    document.body.classList.remove('fixed-container');
    setOpen(false);
  };

  const onChangeValueHandler = (value: OrderFilters[Filter], name: Filter) => {
    setFilters((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onResetFilter = useCallback(() => {
    reset();
    setFilters(initialFilters);
    onFilter(initialFilters);
  }, [onFilter, reset]);

  useEffect(() => {
    if (orderTabType !== prevOrderTabType) {
      onResetFilter();
    }
  }, [onFilter, onResetFilter, orderTabType, prevOrderTabType, reset]);

  const submitFiltersHandler = () => {
    onFilter(filters);
    onCloseFilters();
  };

  const prepareFilterData = (val: MultiValue<OptionType> | SingleValue<OptionType>) => {
    return val instanceof Array ? val.map((x) => x.value) : [val?.value];
  };

  // Маленький экран
  if (isPageMaxWidth) {
    return (
      <div className="flex flex-col">
        {!open ? (
          <div
            className="mb-4 flex cursor-pointer items-center gap-2 self-start"
            onClick={onShowFilters}
          >
            <div>
              <FilterIcon />
            </div>
            <p className="text-base font-semibold">Фильтры</p>
            {!isSmall && activeFiltersLength ? (
              <div className="flex h-5 w-5 items-center justify-center rounded-full bg-blue font-montserrat text-white">
                {activeFiltersLength}
              </div>
            ) : null}
          </div>
        ) : null}

        <dialog className="fixed top-0 z-30 m-0 h-screen w-full p-4" open={open}>
          <form onSubmit={handleSubmit(submitFiltersHandler)} className="flex h-full flex-col">
            <div className="mb-3 flex items-start justify-between">
              <h1 onClick={onCloseFilters}>Фильтры</h1>
              <CloseIcon onClick={onCloseFilters} className="h-5 w-5 p-[3px] text-gray-dark" />
            </div>
            <div className="grow">
              <div className="mb-4">
                <SettlementsSelect
                  isMulti
                  control={control}
                  label="Города обслуживания"
                  name="cityFiasIds"
                  onChange={(val: any) => {
                    onChangeValueHandler(val, 'cities');
                  }}
                  isFormDisabled={false}
                  isClearable
                />
                <FieldSelect
                  name="cityFiasIds"
                  control={control}
                  isMulti
                  variant="filter"
                  placeholder="Город"
                  onChange={(val: any) => {
                    onChangeValueHandler(val, 'cities');
                  }}
                  options={transformToSelectOptions<Settlement>(cities, 'objectFullName', 'fiasId')}
                  onInputChange={(newValue: string) => {
                    getCities(newValue);
                  }}
                />
              </div>
              <div className="mb-4">
                <FieldInput
                  name="customId"
                  label=""
                  placeholder="Номер заявки"
                  control={control}
                  onChange={(val) => {
                    val && onChangeValueHandler(val, 'customId');
                  }}
                />
              </div>
              <div className="mb-4">
                <Controller
                  name="create_date"
                  control={control}
                  render={({ field }) => {
                    return (
                      <DateRangePicker
                        placeholder="Дата создания заявки"
                        value={field.value}
                        onChange={(val) => {
                          onChangeValueHandler(val, 'create_date');
                          field.onChange(val);
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div className="mb-4">
                <FieldSelect
                  variant="filter"
                  placeholder="Клиент"
                  name="clients"
                  control={control}
                  onChange={(val) => {
                    onChangeValueHandler(
                      prepareFilterData(
                        val as unknown as MultiValue<OptionType> | SingleValue<OptionType>
                      ),
                      'clients'
                    );
                  }}
                  options={transformToSelectOptions<EntityShort>(projects, 'shortName', 'id')}
                  isMulti
                />
              </div>
            </div>

            {((isPageMaxWidth && !openCalendar) || !isPageMaxWidth) && (
              <>
                <button className="btn-accent mb-6 w-full max-w-[288px] self-center" type="submit">
                  ПРИМЕНИТЬ ФИЛЬТРЫ
                </button>
                <button
                  className="btn-ghost w-full max-w-[288px] self-center"
                  type="button"
                  onClick={onResetFilter}
                >
                  СБРОСИТЬ ФИЛЬТРЫ
                </button>
              </>
            )}
          </form>
        </dialog>
      </div>
    );
  } else {
    return (
      <form onSubmit={handleSubmit(submitFiltersHandler)} className="relative flex flex-col gap-6">
        <div className="-mt-8 flex justify-end">
          <button
            className="headline-normal border-0 bg-transparent text-xs text-primary-60 hover:font-semibold"
            type="button"
            onClick={onResetFilter}
          >
            Очистить
          </button>
        </div>
        <FieldSelect
          name="cityFiasIds"
          control={control}
          isMulti
          variant="filter"
          label="Город"
          isClearable
          // onChange={(val) => {
          //   onChangeValueHandler(
          //     prepareFilterData(val as unknown as MultiValue<OptionType> | SingleValue<OptionType>),
          //     'cityFiasIds'
          //   );
          // }}
          // onChange={onChangeValueHandler}
          onChange={(val: any) => {
            onChangeValueHandler(val, 'cities');
          }}
          options={transformToSelectOptions<Settlement>(cities, 'objectFullName', 'fiasId')}
          onInputChange={(newValue: string) => {
            if (newValue.length < 3) {
              return;
            }
            getCities(newValue);
          }}
        />
        <Controller
          name="create_date"
          control={control}
          render={({ field }) => {
            return (
              <DateRangePicker
                label="Дата создания заявки"
                value={field.value}
                onChange={(val) => {
                  onChangeValueHandler(val, 'create_date');
                  field.onChange(val);
                }}
              />
            );
          }}
        />
        <FieldSelect
          variant="filter"
          placeholder="Клиент"
          name="clients"
          control={control}
          isClearable
          onChange={(val) => {
            onChangeValueHandler(
              prepareFilterData(val as unknown as MultiValue<OptionType> | SingleValue<OptionType>),
              'clients'
            );
          }}
          options={transformToSelectOptions<EntityShort>(projects, 'shortName', 'id')}
          isMulti
        />
        <FieldInput
          name="customId"
          label="Номер заявки"
          control={control}
          onChange={(val) => {
            // onChangeValueHandler(val.target.value, 'customId');
            val && onChangeValueHandler(val, 'customId');
          }}
        />
        <div className="flex items-center justify-end">
          <Button type="submit">ПРИМЕНИТЬ</Button>
        </div>
      </form>
    );
  }
};
