import { BankIcon } from 'assets/icons';
import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { OrganizationType } from 'enums/organizationType';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { checkBikAndBankSettlement } from 'pages/ModerationRequest/ExecutorModeration/steps/step3/consts';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { subcontractorModerationRequestActions } from 'reduxStore/reducers/SubcontractorModerationSlice';
import { getSubcontractorModerationRequestData } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorModerationRequestData';
import { getSubcontractorNavigation } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorNavigation';
import { Step3Fields } from './Step3Fileds';
import { checkKPP, checkOKATO, checkOKPO } from './consts';
import { Step3Payload } from './types';
import { toast } from 'react-toastify';

export const Step3Subcontractor = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const step = pathname.split('step')[1];

  const { bankSettlement, bankName, bik, withVat, files, organizationTypeId, kpp, okato, okpo } =
    useAppSelector(getSubcontractorModerationRequestData);
  const isNavigateDisabled = useAppSelector(getSubcontractorNavigation);
  const disableNavigate = (value: boolean) => {
    dispatch(subcontractorModerationRequestActions.setIsNavigateDisabled(value));
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<Step3Payload>({
    defaultValues:
      organizationTypeId === OrganizationType.Individual
        ? {
            bankSettlement,
            bankName,
            bik,
            withVat: true,
            files,
          }
        : { bankSettlement, bankName, bik, withVat: true, files, kpp, okato, okpo },
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<Step3Payload> = (data, event) => {
    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as HTMLButtonElement;

    if (organizationTypeId === OrganizationType.Individual) {
      let toastErrors: string[] = [];
      if (data.bankSettlement || data.bik) {
        toastErrors = checkBikAndBankSettlement(
          data.bankSettlement,
          data.bik,
          toastErrors,
          setError
        );
      }
      if (toastErrors.length) {
        const uniqueMessages = new Set(toastErrors);
        Array.from(uniqueMessages).forEach((message: string) => {
          toast.error(message);
        });
        return;
      }
      dispatch(subcontractorModerationRequestActions.setBankProps(data));
      if (isNavigateDisabled) {
        toast.error('Дождитесь удаления файла');
      } else if (submitter.name === 'forward') {
        const nextStep = +step + 1;
        navigate(`/moderation-request/step${nextStep}`);
      } else if (submitter.name === 'back') {
        const prevStep = +step - 1;
        prevStep
          ? prevStep && navigate(`/moderation-request/step${prevStep}`)
          : navigate(`/moderation-request`);
      }
    } else {
      let toastErrors: string[] = [];
      if (data.bankSettlement || data.bik) {
        toastErrors = checkBikAndBankSettlement(
          data.bankSettlement,
          data.bik,
          toastErrors,
          setError
        );
      }
      if (data.kpp) {
        toastErrors = checkKPP(data.kpp, toastErrors, setError);
      }
      if (data.okato) {
        toastErrors = checkOKATO(data.okato, toastErrors, setError);
      }
      if (data.okpo) {
        toastErrors = checkOKPO(data.okpo, toastErrors, setError);
      }
      if (toastErrors.length) {
        const uniqueMessages = new Set(toastErrors);
        Array.from(uniqueMessages).forEach((message: string) => {
          toast.error(message);
        });
        return;
      }
      dispatch(subcontractorModerationRequestActions.setLLCBankProps(data));
      if (isNavigateDisabled) {
        toast.error('Дождитесь удаления файла');
      } else if (submitter.name === 'forward') {
        const nextStep = +step + 1;
        navigate(`/moderation-request/step${nextStep}`);
      } else if (submitter.name === 'back') {
        const prevStep = +step - 1;
        prevStep
          ? prevStep && navigate(`/moderation-request/step${prevStep}`)
          : navigate(`/moderation-request`);
      }
    }
  };

  return (
    <FormWrapper
      title="Банковские реквизиты"
      status={ModerationRequestStatusId.Draft}
      Icon={BankIcon}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex min-h-form flex-col justify-between">
        <Step3Fields
          errors={errors}
          control={control}
          isFormDisabled={false}
          clearErrors={clearErrors}
          organizationTypeId={organizationTypeId}
          disableNavigate={disableNavigate}
        />
        <FormControl />
      </form>
    </FormWrapper>
  );
};
