import { AxiosError, AxiosResponse } from 'axios';
import classNames from 'classnames';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { FileService } from 'services/FileService';
import { FileErrorResponse, OrderFile } from 'types';
import { deleteFile, handleFileDownload } from 'utils/helpers';
import { UploadFileExtensions } from 'utils/settings';
import { File } from '../File';
import { FilePreview } from '../FilePreview';
import { FilePreviewType } from '../FilePreview/types';
import { Loader } from '../Loader';
import { ValidationError } from '../ValidationError';
import { UploadFileInputProps } from './types';
import { UploadFileIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { Spinner } from 'ui/Spinner';
import { toast } from 'react-toastify';

export const UploadFileInput: FC<UploadFileInputProps> = ({
  name,
  onChange,
  fileBusinessTypeId = 6,
  deleteLink = true,
  label = '',
  maxFileSize = 10,
  errorMessage,
  disabled = false,
  value,
  acceptExtensions = UploadFileExtensions.pdfAndImages,
  isHideDeleteFileButton = false,
  isHidePreviewFileButton = false,
  isHideDownloadFileButton = false,
  uploadLimit = 5,
  showUploadLimit = false,
  info,
  infoDisabled,
  description,
  infoActionLabel,
  infoActionLabelClickHandle,
  otherTextInfo,
  className,
  filters,
  disableNavigate,
  clearErrors,
}) => {
  const currentValue = useMemo(() => value ?? [], [value]);
  const [showErrorMessage, setShowErrorMessage] = useState<string>('');
  const [currentFile, setCurrentFile] = useState<FilePreviewType | null>(null);
  const dispatch = useAppDispatch();
  const [dragging, setDragging] = useState(false);
  const [disabledDelete, setDisabledDelete] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // Максимальный размер ОДНОГО файла
  const MAX_SIZE: number = maxFileSize * 1024 * 1024;

  // Загрузка файлов
  const {
    mutateAsync: uploadFile,
    isLoading: isLoadingUploadFile,
    error,
  } = useMutation<AxiosResponse<OrderFile[]>, AxiosError<FileErrorResponse>, FormData>({
    mutationFn: (formData: FormData) => {
      return FileService.uploadFile(formData);
    },
    onSuccess: (response) => {
      uploadLimit === 1
        ? onChange && onChange([...response.data])
        : onChange && onChange([...currentValue, ...response.data]);
    },
  });

  // Просмотр файлов
  const { mutateAsync: previewFile, isLoading: isPreviewLoading } = useMutation({
    mutationFn: (hash: string) => {
      return FileService.fetchFile(hash);
    },
    onSuccess: (response) => {
      setCurrentFile({
        content: response.data.content,
        type: response.data.contentType,
      });
    },
  });

  useEffect(() => {
    setShowErrorMessage('');
  }, [filters]);

  useEffect(() => {
    currentFile &&
      dispatch(
        modalActions.handleOpenModal({
          content: <FilePreview file={currentFile} />,
          options: {
            title: 'Просмотр',
            // modalClassName: 'mx-auto',
            contentClassName: 'flex w-screen sm:w-auto items-center justify-center',
          },
        })
      );
  }, [currentFile]);

  // Скачивание файлов
  const { mutateAsync: downloadFile } = useMutation({
    mutationFn: (hash: string) => {
      return FileService.fetchFile(hash);
    },
    onSuccess: (response) => handleFileDownload(Promise.resolve(response)),
  });

  const handleFileDelete = async (file: OrderFile) => {
    setDisabledDelete(true);
    disableNavigate && disableNavigate(true);
    const response = await deleteFile(file.deletedFileIdHash);
    const files = currentValue.filter(
      (uFile) => uFile.deletedFileIdHash !== file.deletedFileIdHash
    );
    onChange && onChange([...files]);
    setDisabledDelete(false);
    disableNavigate && disableNavigate(false);
  };

  const uploadFileError = error?.response?.data.detail;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const _files = e.target.files;
    let _filesLength = Number(_files?.length);

    if (uploadLimit === 1 && currentValue.length) {
      _filesLength--;
    }
    if (uploadLimit - currentValue.length < _filesLength) {
      setShowErrorMessage(`Количество прикрепляемых файлов не может быть больше ${uploadLimit}`);
      return;
    }
    if (_files && _files.length > 0) {
      for (let i = 0; i < _files.length; i++) {
        if (_files[i].size > MAX_SIZE) {
          setShowErrorMessage(
            `Один из выбранных файлов больше ${maxFileSize} мегабайт, выберите файл меньшего размера`
          );
          return;
        }
      }
      setShowErrorMessage('');

      const formData: FormData = new FormData();

      Array.from(_files).forEach((file) => {
        const blob = new Blob([file], { type: file.type });
        formData.append('files', blob, file.name);
      });
      formData.append('FileBuisenessTypeId', String(fileBusinessTypeId));
      formData.append('DeleteLink', String(deleteLink));

      uploadFile(formData);
    }
    e.target.value = '';
  };

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (!dragging) {
        setDragging(true);
      }
    },
    [dragging]
  );

  const handleDragLeave = useCallback(() => {
    setDragging(false);
  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);

    const inputElement = document.createElement('input');
    inputElement.type = 'file';

    const changeEvent = new Event('change', { bubbles: true });
    Object.defineProperty(changeEvent, 'target', { writable: false, value: inputElement });

    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      inputElement.files = e.dataTransfer.files;
      handleFileChange(changeEvent as unknown as ChangeEvent<HTMLInputElement>);
    }
  }, []);

  const handleDivClick = () => {
    clearErrors && clearErrors(name);
    if (fileInputRef.current && !disabled && currentValue.length < uploadLimit) {
      fileInputRef.current.click();
      return;
    }
    if (fileInputRef.current && !disabled && uploadLimit === 1) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      {label && <div className="headline-bold mb-4 leading-6 text-text-100">{label}</div>}
      <div
        className={classNames(
          'relative flex flex-col items-center rounded-[0.625rem] border border-light-30 p-3 text-text-50',
          className,
          {
            'border-danger': errorMessage || showErrorMessage,
            'cursor-not-allowed text-text-50 hover:border-light-30 hover:text-text-50':
              (currentValue.length >= uploadLimit && uploadLimit !== 1) || disabled,
            'cursor-pointer hover:border-primary-60 hover:text-primary-60':
              !disabled || uploadLimit === 1,
            'text-danger hover:text-primary-60': errorMessage || showErrorMessage,
          }
        )}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleDivClick}
      >
        <UploadFileIcon className="mx-auto text-text-100" />
        {!disabled ? info : infoDisabled}
        <span
          className={classNames(
            'btn-accent headline-bold mb-4 mt-3 flex max-w-[256px] items-center justify-center text-primary-60',
            {
              'pointer-events-none !text-text-50':
                (currentValue.length >= uploadLimit && uploadLimit !== 1) || disabled,
              'text-inherit': errorMessage || showErrorMessage,
            }
          )}
        >
          {uploadLimit !== 1
            ? 'Добавить файлы'
            : currentValue?.length
            ? 'Заменить файл'
            : 'Добавить файл'}
        </span>
        <input
          type="file"
          className="hidden"
          ref={fileInputRef}
          id={`download-file-${fileBusinessTypeId}-${name}`}
          accept={acceptExtensions}
          multiple
          onChange={handleFileChange}
          name={name}
          disabled={disabled}
        />
        <div className="text-center text-text-50">
          <p>
            Допустимые типы:{' '}
            {acceptExtensions === UploadFileExtensions.pdfAndImages
              ? 'jpg, jpeg, pdf, png'
              : acceptExtensions.slice(1)}
          </p>
          <p>Максимальный размер файла: {maxFileSize} мб</p>
          {otherTextInfo && <p>{otherTextInfo}</p>}
          {/* {showUploadLimit && <p>{`Допустимо приложить: не более ${uploadLimit} файлов`}</p>} */}
          {uploadLimit && uploadLimit === 1 && (
            <p>{`Допустимо приложить: не более ${uploadLimit} файла`}</p>
          )}
          {uploadLimit && uploadLimit !== 1 && (
            <p>{`Допустимо приложить: не более ${uploadLimit} файлов`}</p>
          )}
        </div>
      </div>
      <ValidationError errorMessage={showErrorMessage} />
      {isPreviewLoading || disabledDelete ? (
        <Spinner />
      ) : (
        (currentValue.length > 0 || isLoadingUploadFile) && (
          <div className="mt-1 w-full">
            {currentValue.map((file) => {
              return (
                <File
                  file={file}
                  key={file.downloadFileIdHash}
                  onDownload={() => downloadFile(file.downloadFileIdHash)}
                  onPreview={() => previewFile(file.downloadFileIdHash)}
                  onDelete={
                    disabledDelete
                      ? () => toast.error('Дождитесь удаления файла')
                      : () => handleFileDelete(file)
                  }
                  disabled={disabled}
                  isHideDeleteFileButton={
                    isHideDeleteFileButton || file?.deletedFileIdHash?.length < 1 ? true : false
                  }
                  // isHideDeleteFileButton={isHideDeleteFileButton}
                  isHidePreviewFileButton={isHidePreviewFileButton}
                  isHideDownloadFileButton={isHideDownloadFileButton}
                />
              );
            })}
            <Loader show={isLoadingUploadFile} />
          </div>
        )
      )}
      <ValidationError errorMessage={uploadFileError ?? ''} className="callout-normal" />
      {description && (
        <div
          className={classNames('callout-normal mt-1 text-text-50', {
            'text-danger': errorMessage,
          })}
        >
          {description}
        </div>
      )}
      {infoActionLabel && (
        <button
          className="callout-medium mt-1 cursor-pointer text-primary-60"
          onClick={infoActionLabelClickHandle}
        >
          {infoActionLabel}
        </button>
      )}
      <ValidationError errorMessage={errorMessage ?? ''} className="callout-normal" />
    </>
  );
};
