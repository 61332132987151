import { UseFormSetError } from 'react-hook-form';

export const vatRadio = [
  {
    label: 'С НДС',
    value: true,
  },
  {
    label: 'Без НДС',
    value: false,
  },
];

export const checkKPP = (kpp: string, toastErrors: string[], setError: UseFormSetError<any>) => {
  if (kpp) {
    kpp = kpp.replace(/_/g, '');
    if (kpp.length < 9) {
      setError('kpp', {
        type: 'manual',
        message: 'Неверный формат КПП. КПП должен состоять из 9 цифр',
      });
      toastErrors.push('Неверный формат КПП. КПП должен состоять из 9 цифр');
    }
  }
  return toastErrors;
};

export const checkOKPO = (okpo: string, toastErrors: string[], setError: UseFormSetError<any>) => {
  if (okpo) {
    okpo = okpo.replace(/_/g, '');
    if (okpo.length < 8) {
      setError('okpo', {
        type: 'manual',
        message: 'Неверный формат ОКПО. ОКПО должен состоять из 8 цифр',
      });
      toastErrors.push('Неверный формат ОКПО. ОКПО должен состоять из 8 цифр');
    }
  }
  return toastErrors;
};

export const checkOKATO = (
  okato: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (okato) {
    okato = okato.replace(/_/g, '');
    if (okato.length < 11) {
      setError('okato', {
        type: 'manual',
        message: 'Неверный формат ОКАТО. ОКАТО должен состоять из 11 цифр',
      });
      toastErrors.push('Неверный формат ОКАТО. ОКАТО должен состоять из 11 цифр');
    }
  }
  return toastErrors;
};
