import { FieldInput } from 'components/redesign/FieldInput';
import { FieldInput as FieldInputWithMask } from 'components/FieldInput';
import { FieldUploadFileInput } from 'components/redesign/FieldUploadFileInput';
import { FileBusinessType } from 'enums/fileBusinessType';
import { FC } from 'react';
import { Step2Props } from './types';
import { ESubcontractorModerationRequestStatusId } from 'enums/subcontractorModerationRequestStatus';
import { ESubcontractorModerFormName } from 'pages/ModerationRequest/types';
import { IS_SMALL_DEVICE, UploadFileExtensions } from 'utils/settings';
import useMediaQuery from 'hooks/useMediaQuery';
import { OrganizationType } from 'enums/organizationType';
import { FieldTextarea } from 'components/redesign/FieldTextArea';

export const Step2Fields: FC<Step2Props> = ({
  control,
  isFormDisabled,
  errors,
  clearErrors,
  moderationRequestData,
  checkFieldInChecklist,
  organizationTypeId,
  disableNavigate,
}) => {
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      <FieldTextarea
        name="legalAddress"
        control={control}
        label="Юридический адрес"
        isClearable
        errorMessage={errors?.legalAddress?.message}
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.legalAddress)
            : isFormDisabled
        }
        maxLength={1000}
        clearErrors={clearErrors}
      />
      <FieldTextarea
        name="address"
        control={control}
        label="Фактический адрес"
        isClearable
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.address)
            : isFormDisabled
        }
        errorMessage={errors?.address?.message}
        maxLength={1000}
        clearErrors={clearErrors}
      />
      <FieldInputWithMask
        name="phoneNumber"
        control={control}
        label="Телефон"
        type="tel"
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.phoneNumber)
            : isFormDisabled
        }
        className={'-mt-[5px]'}
        errorMessage={errors?.phoneNumber?.message}
        clearErrors={clearErrors}
      />
      <FieldTextarea
        name="email"
        // type="email"
        control={control}
        label="Электронная почта"
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.email)
            : isFormDisabled
        }
        errorMessage={errors?.email?.message}
        maxLength={100}
        isClearable
        clearErrors={clearErrors}
      />
      {organizationTypeId === OrganizationType.LLC && (
        <FieldInput
          name="inn"
          control={control}
          label="ИНН"
          isClearable
          disabled={
            moderationRequestData?.statusId ===
            ESubcontractorModerationRequestStatusId.ElaborationRequest
              ? checkFieldInChecklist &&
                checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.inn)
              : isFormDisabled
          }
          errorMessage={errors?.inn?.message}
          mask="9999999999"
          clearErrors={clearErrors}
        />
      )}
      {organizationTypeId === OrganizationType.Individual && (
        <FieldInput
          name="inn"
          control={control}
          label="ИНН"
          isClearable
          disabled={
            moderationRequestData?.statusId ===
            ESubcontractorModerationRequestStatusId.ElaborationRequest
              ? checkFieldInChecklist &&
                checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.inn)
              : isFormDisabled
          }
          errorMessage={errors?.inn?.message}
          mask="999999999999"
          clearErrors={clearErrors}
        />
      )}
      {organizationTypeId === OrganizationType.Individual && (
        <FieldInput
          name="ogrnip"
          control={control}
          label="ОГРНИП"
          isClearable
          disabled={
            moderationRequestData?.statusId ===
            ESubcontractorModerationRequestStatusId.ElaborationRequest
              ? checkFieldInChecklist &&
                checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.ogrnip)
              : isFormDisabled
          }
          errorMessage={errors?.ogrnip?.message}
          mask="999999999999999"
          clearErrors={clearErrors}
        />
      )}
      {organizationTypeId === OrganizationType.LLC && (
        <FieldInput
          name="ogrn"
          control={control}
          label="ОГРН"
          isClearable
          disabled={
            moderationRequestData?.statusId ===
            ESubcontractorModerationRequestStatusId.ElaborationRequest
              ? checkFieldInChecklist &&
                checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.ogrn)
              : isFormDisabled
          }
          errorMessage={errors?.ogrn?.message}
          mask="9999999999999"
          clearErrors={clearErrors}
        />
      )}
      <span className="headline-bold sm:title2-bold -my-2">ИНН</span>
      {!isSmall && (
        <span className="headline-bold sm:title2-bold -my-2">
          {organizationTypeId === OrganizationType.Individual ? 'ОГРНИП' : 'ОГРН'}
        </span>
      )}
      <FieldUploadFileInput
        name={'innScan'}
        control={control}
        acceptExtensions={UploadFileExtensions.inn}
        errorMessage={(errors as any)?.innScan?.message}
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.innScan)
            : isFormDisabled
        }
        fileBusinessTypeId={FileBusinessType.Inn}
        clearErrors={clearErrors}
        uploadLimit={1}
        disableNavigate={disableNavigate}
      />
      {isSmall && (
        <span className="headline-bold sm:title2-bold -my-2">
          {organizationTypeId === OrganizationType.Individual ? 'ОГРНИП' : 'ОГРН'}
        </span>
      )}
      {organizationTypeId === OrganizationType.Individual && (
        <FieldUploadFileInput
          name={'ogrnipScan'}
          control={control}
          errorMessage={(errors as any)?.ogrnipScan?.message}
          disabled={
            moderationRequestData?.statusId ===
            ESubcontractorModerationRequestStatusId.ElaborationRequest
              ? checkFieldInChecklist &&
                checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.ogrnipScan)
              : isFormDisabled
          }
          fileBusinessTypeId={FileBusinessType.Ogrnip}
          clearErrors={clearErrors}
          uploadLimit={1}
          disableNavigate={disableNavigate}
        />
      )}
      {organizationTypeId === OrganizationType.LLC && (
        <FieldUploadFileInput
          name={'ogrnScan'}
          control={control}
          errorMessage={(errors as any)?.ogrnScan?.message}
          disabled={
            moderationRequestData?.statusId ===
            ESubcontractorModerationRequestStatusId.ElaborationRequest
              ? checkFieldInChecklist &&
                checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.ogrnScan)
              : isFormDisabled
          }
          fileBusinessTypeId={FileBusinessType.Ogrn}
          clearErrors={clearErrors}
          uploadLimit={1}
          disableNavigate={disableNavigate}
        />
      )}
    </div>
  );
};
