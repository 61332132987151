import classNames from 'classnames';
import { useMemo } from 'react';
import ReactSelect, {
  ActionMeta,
  ControlProps,
  GroupBase,
  MultiValue,
  Props,
  SingleValue,
  StylesConfig,
} from 'react-select';
import { isMultiComponents } from 'ui/redesign/Select/utils';
import { ValidationError } from 'ui/redesign/ValidationError';

export type OptionType<T = any> = {
  value: T;
  label: string | any;
  [x: string]: any;
};

export interface SelectProps extends Props<OptionType> {
  placeholder?: string;
  onChangeValue: (
    value: MultiValue<OptionType> | SingleValue<OptionType> | null,
    meta?: ActionMeta<OptionType>
  ) => void;
  isMulti?: boolean;
  options?: OptionType[];
  value?: OptionType | OptionType[] | null;
  variant?: 'filter' | 'clock' | 'cities' | 'areas';
  errorMessage?: string;
  label?: string;
}

export const Select = (props: SelectProps) => {
  const {
    placeholder = '',
    onChangeValue,
    isMulti = false,
    options,
    value,
    variant,
    errorMessage = '',
    label,
    ...other
  } = props;
  const getControlBorderColor = (
    state: ControlProps<OptionType, boolean, GroupBase<OptionType>>
  ) => {
    if (errorMessage) {
      return '1px solid #ff4728';
    }
    return state.isFocused ? '2px solid #4251DA' : '1px solid #e4e4e5';
  };

  const getControlBoxShadow = (state: ControlProps<OptionType, boolean, GroupBase<OptionType>>) => {
    if (errorMessage) {
      return '0 0 4px rgba(232,72,78,0.55)';
    }
    return state.isFocused ? '0 0 4px rgba(0,200,200,0.4)' : 'none';
  };

  const filterStyle: StylesConfig<OptionType, boolean, GroupBase<OptionType>> | undefined = {
    container: (base) => ({
      ...base,
      paddingBottom: 0,
      color: '#BFBFBF',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 16,
      color: '#BFBFBF',
    }),
    control: (base, state) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      borderBottom: state.isFocused ? '2px solid #4251DA' : '1px solid #E3E3E3',
      borderRadius: 0,
      cursor: 'pointer',
      '&:hover': {
        borderBottom: '2px solid #4251DA',
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      transform: 'rotate(270deg)',
      color: '#848484',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#848484',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 8,
      marginTop: 6,
      marginBottom: 6,
      boxShadow: '0px 10px 34px rgba(175, 196, 208, 0.471014)',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: -4,
        left: 32,
        width: 12,
        height: 12,
        backgroundColor: '#fff',
        transform: 'rotate(45deg)',
        boxShadow: '0px 0px 10px rgba(123, 123, 123, 0.25)',
      },
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 8,
      backgroundColor: '#fff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#F1F1F2' : '#fff',
      // backgroundColor: state.isFocused ? '#000' : state.data.isDisabled ? '#E3E3E3' : '',
      fontSize: 12,
      lineHeight: 1.2,
      padding: '9px 8px',
      color: 'black',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    multiValue: (base) => ({
      ...base,
      background: '#F1F1F1',
      borderRadius: 8,
      color: 'black',
      fontSize: 14,
    }),
    singleValue: (base) => ({
      ...base,
      color: 'black',
      fontSize: 16,
      fontWeight: '600',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: '#848484',
    }),
  };

  const defaultStyle: StylesConfig<OptionType, boolean, GroupBase<OptionType>> | undefined = {
    container: (base) => ({
      ...base,
      fontSize: 12,
      lineHeight: 1.2,
    }),
    placeholder: (base) => ({
      ...base,
    }),
    control: (base, state) => ({
      ...base,
      border: getControlBorderColor(state),
      borderRadius: 8,
      cursor: 'pointer',
      fontSize: 16,
      lineHeight: 1.2,
      padding: '13px 33px 13px 8px',
      minHeight: 48,
      '&:hover': {
        borderColor: '#00C8C8',
      },
      backgroundColor: 'bg-white',
    }),
    indicatorSeparator: () => {
      return {
        display: 'none',
      };
    },
    indicatorsContainer: (base) => ({
      ...base,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: '#848484',
      padding: '0 0 0 8px',
      position: 'absolute',
      top: '50%',
      right: 8,
      translate: '0 -50%',
      display: state.isDisabled ? 'none' : 'flex',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 8,
      marginTop: 6,
      marginBottom: 6,
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 8,
      backgroundColor: '#fff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#00C8C833' : state.data.isDisabled ? '#E3E3E3' : '',
      fontSize: 12,
      lineHeight: 1.2,
      padding: '9px 8px',
      color: 'black',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    singleValue: (base) => ({
      ...base,
      textOverflow: 'clip',
      whiteSpace: 'normal',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: 0,
    }),
    input: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),
  };

  const clockStyle: StylesConfig<OptionType, boolean, GroupBase<OptionType>> | undefined = {
    ...defaultStyle,
    control: (base, state) => ({
      ...(defaultStyle.control && defaultStyle.control(base, state)),
      fontSize: 24,
      lineHeight: 1.20833333,
      fontWeight: 600,
      fontFamily: 'Montserrat',
      padding: '9px 33px 8px 8px',
    }),
  };

  const citiesStyle: StylesConfig<OptionType, boolean, GroupBase<OptionType>> | undefined = {
    ...defaultStyle,
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#ccf4f4' : '',
      borderRadius: 12,
      fontSize: 16,
      padding: 16,
      color: state.isSelected ? '#BFBFBF' : 'black',
    }),
    multiValue: (base) => ({
      ...base,
      background: '#F1F1F1',
      borderRadius: 8,
      color: 'black',
      fontSize: 14,
    }),
    singleValue: (base) => ({
      ...base,
      color: 'black',
      fontSize: 16,
      fontWeight: '600',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: '#848484',
    }),
    container: (base) => ({
      ...base,
      color: '#BFBFBF',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 16,
      color: '#BFBFBF',
    }),
    control: (base, state) => ({
      ...base,
      border: getControlBorderColor(state),
      boxShadow: getControlBoxShadow(state),
      borderRadius: 8,
      cursor: 'pointer',
      fontSize: 16,
      lineHeight: 1.2,
      padding: '13px 33px 13px 8px',
      minHeight: 48,
      '&:hover': {
        borderColor: errorMessage ? '#E8474E' : '',
      },
      backgroundColor: 'bg-white',
    }),
  };

  const areasStyle: StylesConfig<OptionType, boolean, GroupBase<OptionType>> | undefined = {
    ...defaultStyle,
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#F1F1F2' : '#fff',
      borderRadius: 12,
      fontSize: 16,
      padding: 16,
      color: state.isSelected ? '#BFBFBF' : 'black',
      cursor: 'pointer',
    }),
    multiValue: (base) => ({
      ...base,
      background: '#E4E4E5',
      borderRadius: 8,
      color: 'black',
      fontSize: 14,
    }),
    singleValue: (base) => ({
      ...base,
      color: 'black',
      fontSize: 16,
      fontWeight: '600',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: '#848484',
      paddingTop: '3px',
      paddingBottom: '3px',
      borderRadius: '24px',
      '&:hover': {
        backgroundColor: '#9FA0A7',
        color: '#000',
      },
    }),
    container: (base) => ({
      ...base,
      color: '#BFBFBF',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 16,
      color: '#BFBFBF',
    }),
    control: (base, state) => ({
      ...base,
      border: 0,
      // borderBottom: '1px solid #E4E4E5',
      boxShadow: 'none',
      borderRadius: 0,
      cursor: 'pointer',
      fontSize: 16,
      lineHeight: 1.2,
      // padding: '1px 33px 13px 8px',
      minHeight: 31,
      borderBottom: getControlBorderColor(state),
      '&:hover': {
        borderColor: '#4251DA',
      },
      backgroundColor: 'bg-white',
    }),
    loadingIndicator: (base) => ({
      ...base,
      marginRight: '50px',
    }),
  };

  let styles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> | undefined;

  switch (variant) {
    case 'filter':
      styles = filterStyle;
      break;
    case 'clock':
      styles = clockStyle;
      break;
    case 'cities':
      styles = citiesStyle;
      break;
    case 'areas':
      styles = areasStyle;
      break;
    default:
      styles = defaultStyle;
  }

  const { components: selectComponents } = useMemo(() => {
    let components;
    if (isMulti) {
      components = isMultiComponents;
    }
    switch (variant) {
      case 'areas':
        components = isMultiComponents;
        break;
      default:
        break;
    }
    return {
      components,
    };
  }, [variant, isMulti]);

  return (
    <>
      {label && (
        <div
          className={classNames('body-normal text-text-50', {
            '!text-primary-60':
              label === 'Федеральный округ' ||
              label === 'Субъект Федерации' ||
              label === 'Населенный пункт обслуживания',
          })}
        >
          {label}
        </div>
      )}
      <ReactSelect
        value={value}
        isMulti={isMulti}
        isClearable={false}
        options={options}
        components={selectComponents}
        noOptionsMessage={() => <p>Нет доступных для выбора значений</p>}
        loadingMessage={() => <p>Подождите, идёт загрузка...</p>}
        placeholder={placeholder}
        onChange={(option, meta) => onChangeValue(option, meta)}
        styles={styles}
        {...other}
      />
      {errorMessage && <ValidationError errorMessage={errorMessage} />}
    </>
  );
};
