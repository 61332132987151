import { yupResolver } from '@hookform/resolvers/yup';
import { FieldTextarea } from 'components/FieldTextarea';
import { FieldUploadFileInput } from 'components/redesign/FieldUploadFileInput';
import { FileBusinessType } from 'enums/fileBusinessType';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Order } from 'types';
import { FinalStatusFormType, Statuses } from 'types/status';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { array, number, object, string } from 'yup';
import { styles } from './consts';

interface FinalStatusForm {
  order: Order | undefined;
  newStatus: number;
  onClose: () => void;
  onStatusChange: (formData: FinalStatusFormType) => void;
}

const completeSchema = object()
  .shape({
    description: string()
      .transform((value) => value.trim())
      // .max(1000, 'Длина содержимого поля "Описание" должно быть не более 1000 символов')
      .required('"Описание" должно быть заполнено'),
    orderWorkActFiles: array()
      .of(
        object().shape({
          deletedFileIdHash: string(),
          downloadFileIdHash: string(),
          fileName: string(),
          fileType: number(),
          id: number(),
        })
      )
      .min(1, 'Прикрепите акт выполненных работ'),
  })
  .required();

const cancelSchema = object().shape({
  description: string().max(
    1000,
    'Длина содержимого поля "Описание" должно быть не более 1000 символов'
  ),
  // .required('Описание должно быть заполнено'),
});
// .required();

export const FinalStatusForm = ({ order, newStatus, onClose, onStatusChange }: FinalStatusForm) => {
  const methods = useForm<FinalStatusFormType>({
    defaultValues: {
      description: order?.worksInfo?.description || '',
      orderWorkActFiles: order?.worksInfo?.workActFiles || [],
      additionalFiles: order?.worksInfo?.additionalFiles || [],
    },
    mode: 'all',
    resolver: yupResolver(newStatus === 6 ? completeSchema : cancelSchema),
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  const hasErrors = errors && Object.keys(errors).length > 0;

  const submitFormHandler: SubmitHandler<FinalStatusFormType> = (values) => {
    values.description = values.description.trim();
    if (!hasErrors) {
      onStatusChange(values);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitFormHandler)}>
        <div className="mb-4 flex h-full flex-col justify-between gap-4">
          <div className="flex-1">
            <div className="my-4 flex flex-row justify-between">
              <div>
                <p className={styles.label}>Текущий статус</p>
                {order && (
                  <p className="title2-bold text-text-100">{Statuses[order.status].name}</p>
                )}
              </div>
              <div>
                <p className={styles.label}>Новый статус заявки</p>
                {/* <p className="title2-bold text-primary-60">{Statuses[newStatus].name}</p> */}
                <p className="title2-bold text-primary-60">{Statuses[5].name}</p>
              </div>
            </div>
            <FieldTextarea
              maxLength={1000}
              name="description"
              label="Описание"
              control={control}
              errors={errors}
            />
            <p className="headline-bold my-4 text-text-100">Акт выполненных работ</p>
            <FieldUploadFileInput
              control={control}
              errorMessage={(errors as any)?.orderWorkActFiles?.message}
              name="orderWorkActFiles"
              fileBusinessTypeId={FileBusinessType.OrderWorkAct}
              uploadLimit={45}
            />
            <p className="headline-bold my-4 text-text-100">Дополнительные вложения</p>
            <FieldUploadFileInput
              control={control}
              errorMessage={(errors as any)?.additionalFiles?.message}
              uploadLimit={45}
              name="additionalFiles"
              fileBusinessTypeId={FileBusinessType.Other}
            />
          </div>

          <div className="mt-4 flex flex-col items-center justify-end gap-3 sm:flex-row">
            <Button
              variant={ButtonVariant.OUTLINE}
              type="button"
              onClick={onClose}
              className="w-full max-w-[640px] sm:max-w-[130px]"
            >
              ОТМЕНА
            </Button>
            <Button
              className="w-full max-w-[640px] sm:max-w-[230px]"
              type="submit"
              disabled={!watch('orderWorkActFiles')}
            >
              СОХРАНИТЬ
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
