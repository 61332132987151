import { BankIcon } from 'assets/icons';
import { ExecutorTypeEnum } from 'enums/executorType';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { getModerationRequestStatus } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestStatus';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { Step3Fields } from './Step3Fields';
import { checkBikAndBankSettlement, checkExecutorOgrnip } from './consts';
import { Step3Payload } from './types';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getExecutorNavigation } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getExecutorNavigation';

export const Step3 = () => {
  const {
    status,
    bik,
    bankSettlement,
    selfemployedCertificate,
    individualCertificate,
    executorType,
  } = useAppSelector(getModerationRequestData);
  const { created } = useAppSelector(getProfile);
  const isFormDisabled = useAppSelector(getModerationRequestStatus);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isNavigateDisabled = useAppSelector(getExecutorNavigation);
  const disableNavigate = (value: boolean) => {
    dispatch(moderationRequestActions.setIsNavigateDisabled(value));
  };
  const { pathname } = useLocation();
  const step = pathname.split('step')[1];

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Step3Payload>({
    defaultValues:
      executorType === ExecutorTypeEnum.Selfemployed
        ? {
            bik,
            bankSettlement,
            selfemployedCertificate: selfemployedCertificate?.files,
          }
        : {
            bik,
            bankSettlement,
            ogrnip: individualCertificate?.ogrnip,
            individualName: individualCertificate?.individualName,
            individualCertificateFiles: individualCertificate?.files,
          },
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<Step3Payload> = (data, event) => {
    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as HTMLButtonElement;

    if (executorType === ExecutorTypeEnum.Selfemployed) {
      let toastErrors: string[] = [];
      if (data.bankSettlement || data.bik) {
        toastErrors = checkBikAndBankSettlement(
          data.bankSettlement,
          data.bik,
          toastErrors,
          setError
        );
      }
      if (toastErrors.length) {
        const onlyUnique = new Set(toastErrors);
        Array.from(onlyUnique).forEach((message: string) => {
          toast.error(message);
        });
        return;
      }
      dispatch(moderationRequestActions.setSelfemployedBankProps(data));
      if (isNavigateDisabled) {
        toast.error('Дождитесь удаления файла');
      } else if (submitter.name === 'forward') {
        const nextStep = +step + 1;
        navigate(`/moderation-request/step${nextStep}`);
      } else if (submitter.name === 'back') {
        const prevStep = +step - 1;
        prevStep
          ? prevStep && navigate(`/moderation-request/step${prevStep}`)
          : navigate(`/moderation-request`);
      }
    } else {
      let toastErrors: string[] = [];

      if (data?.bankSettlement || data?.bik) {
        toastErrors = checkBikAndBankSettlement(
          data.bankSettlement,
          data.bik,
          toastErrors,
          setError
        );
      }
      if (
        data?.ogrnip ||
        data?.individualName ||
        (data?.individualCertificateFiles && data?.individualCertificateFiles?.length > 0)
      ) {
        toastErrors = checkExecutorOgrnip(
          data.ogrnip,
          data.individualName,
          data.individualCertificateFiles,
          toastErrors,
          setError
        );
      }
      if (toastErrors.length) {
        const uniqueMessages = new Set(toastErrors);
        Array.from(uniqueMessages).forEach((message: string) => {
          toast.error(message);
        });
        return;
      }
      dispatch(moderationRequestActions.setIndividualBankProps(data));
      if (isNavigateDisabled) {
        toast.error('Дождитесь удаления файла');
      } else if (submitter.name === 'forward') {
        const nextStep = +step + 1;
        navigate(`/moderation-request/step${nextStep}`);
      } else if (submitter.name === 'back') {
        const prevStep = +step - 1;
        prevStep
          ? prevStep && navigate(`/moderation-request/step${prevStep}`)
          : navigate(`/moderation-request`);
      }
    }
  };

  // useEffect(() => {
  //   for (const key in errors) {
  //     toast.error((errors[key as keyof typeof errors] as any).message);
  //   }
  // }, [errors]);

  return (
    <FormWrapper title="Реквизиты" status={status} created={created} Icon={BankIcon}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex min-h-[65vh] flex-col justify-between"
      >
        <Step3Fields
          clearErrors={clearErrors}
          control={control}
          errors={errors}
          isFormDisabled={isFormDisabled}
          executorType={executorType}
          disableNavigate={disableNavigate}
        />
        <FormControl errors={errors} />
      </form>
    </FormWrapper>
  );
};
