import {
  IconInstruction,
  IconModerationRequest,
  IconNewspaper,
  IconOrders,
  IconProfile,
} from 'assets/icons';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';

interface NavLinkModel {
  link: string;
  name: string;
  icon: import('react').ReactNode;
  guardPermission?: ProfilePermissions;
  // guardPermission?: EPermissions;
}

export const navLinks: NavLinkModel[] = [
  { link: '/profile', name: 'Профиль', icon: <IconProfile /> },
  { link: '/moderation-request', name: 'Заявка на модерацию', icon: <IconModerationRequest /> },
  {
    link: '/orders',
    name: 'Заявки',
    icon: <IconOrders />,
    guardPermission: ProfilePermissions.Request_Executor,
  },
  {
    link: '/orders',
    name: 'Заявки',
    icon: <IconOrders />,
    guardPermission: ProfilePermissions.Request_ExecutorSubcontractor,
  },
  {
    link: '/orders',
    name: 'Заявки',
    icon: <IconOrders />,
    guardPermission: ProfilePermissions.AgentRequest_ShowForAgentExecutor,
  },
  // {
  //   link: '/receipts',
  //   name: 'Чеки',
  //   icon: <IconNewspaper />,
  //   guardPermission: ProfilePermissions.AgentRequest_ShowForAgentExecutor,
  // },
  {
    link: '/receipts',
    name: 'Чеки',
    icon: <IconNewspaper />,
    guardPermission: ProfilePermissions.Billing_GetMyExecutorReceipts,
  },
  // { link: '/my_income', name: 'Мой доход', icon: <IconCoinStack /> },
  { link: '/help', name: 'Инструкция', icon: <IconInstruction /> },
  // { link: '/testing', name: 'Тестирование', icon: <IconTesting /> },
];

export const textMotionVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export const SIDEBAR_OPEN_LOCALSTORAGE_NAME = 'sidebar-open';
