import classNames from 'classnames';
import { FC, useEffect, useRef } from 'react';
import { TextareaProps } from './types';
import { ValidationError } from '../ValidationError';
import { CloseIcon } from 'assets/icons';
import { useRenderIcons } from 'hooks/useRenderIcons';

export const Textarea: FC<TextareaProps> = ({
  value = '',
  label,
  icons,
  errorMessage = '',
  disabled = false,
  iconWrapperClassName,
  onChange,
  isClearable = false,
  isOpen,
  ...other
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const renderIcons = useRenderIcons(
    disabled,
    isClearable
      ? [
          {
            icon: (
              <CloseIcon
                className={classNames('-ml-4 text-text-50 hover:text-text-100', {
                  'cursor-not-allowed': disabled,
                })}
              />
            ),
            onClick: () =>
              onChange &&
              onChange({
                target: {
                  value: '',
                },
              } as any),
          },
          ...(icons ?? []),
        ]
      : [...(icons ?? [])],
    iconWrapperClassName
  );

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <div>
      <div
        className={classNames('flex', {
          // 'border-danger': errorMessage,
          // 'border-primary-60': isOpen,
        })}
      >
        <div className="flex grow flex-col justify-end pb-1">
          {label && <div className="body-normal mb-1 !text-text-50">{label}</div>}
          <textarea
            ref={textareaRef}
            onChange={onChange}
            className={classNames(
              'headline-normal h-auto resize-none overflow-hidden border-0 border-b-2 border-solid border-light-50 bg-white pb-[2px] pr-6 !text-text-100 focus:border-primary-60',
              {
                '!text-text-50': disabled || other.readOnly,
                'border-danger': errorMessage,
              }
            )}
            value={value}
            rows={1}
            disabled={disabled}
            {...other}
          />
        </div>
        {renderIcons}
      </div>
      <ValidationError errorMessage={errorMessage} />
    </div>
  );
};
