import { useQuery } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Table } from 'ui/redesign/Table';
import { TableCard } from 'ui/redesign/Table/TableCard';
import { TableLoading } from 'ui/redesign/Table/TableLoading';
import { NoneOrders } from './NoneOrders';
import { OrdersTabs } from './OrdersTabs';
import { OrderTypesEnum } from './enums';
import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { IconApplication, NoOrdersIcon } from 'assets/icons';
import { FilterBlock } from 'components/FiltersBlock';
import useMediaQuery from 'hooks/useMediaQuery';
import { usePrevious } from 'hooks/usePrevious';
import { useEffect, useState } from 'react';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { OrderByFilterRequest, OrderByFilterResponse, OrderForListCard } from 'types';
import { Spinner } from 'ui/Spinner';
import { OrderCardMobile } from 'ui/redesign/OrderCard';
import { getFilter } from 'utils/helpers';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { OrderFilters, initialFilters } from './Filters';
import { OrderFiltersModal, defaultFilters } from './OrdersFiltersModal';
import { ordersTableHeadings, subcontractorOrdersTableHeadings } from './constants';
import { determinateFiltersCount } from './utils';
import { useAppSelector } from 'hooks/useAppSelector';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import { ExecutorTypeEnum } from 'enums/executorType';

const fetchOrders = async (filter: OrderByFilterRequest) => {
  const response = await request<OrderByFilterResponse>(
    endpoints.GET_ORDERS.type,
    endpoints.GET_ORDERS.url(),
    {
      data: JSON.stringify(filter),
      responseType: 'json',
    }
  );
  function filterUniqueById(items: OrderForListCard[]): OrderForListCard[] {
    const uniqueItems: OrderForListCard[] = [];
    const seenIds = new Set<number>();
    for (const item of items) {
      if (!seenIds.has(item.id)) {
        uniqueItems.push(item);
        seenIds.add(item.id);
      }
    }
    return uniqueItems;
  }
  const filterdOrders = filterUniqueById(response.data.pagedList);
  const filteredResponse = {
    ...response.data,
    pagedList: filterdOrders,
    totalCount: filterdOrders?.length,
  };
  return filteredResponse;
};

const OrdersPage = () => {
  const executorType = useAppSelector(getExecutorType);
  const orderType =
    executorType === ExecutorTypeEnum.Subcontractor
      ? OrderTypesEnum.inProgress
      : OrderTypesEnum.new;
  const { type = orderType } = useParams<{ type: OrderTypesEnum }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<OrderFilters>(initialFilters);
  const prevOrderTabType = usePrevious(type);
  const [enabled, setEnabled] = useState(true);
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const [ordersCount, setOrdersCount] = useState<Record<OrderTypesEnum, number>>(
    {} as Record<OrderTypesEnum, number>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    setEnabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleRowClick = (tableRow: TableData): void => {
    navigate(`/orders/${type}/${tableRow.id}`);
  };

  const { isFetching, data, refetch } = useQuery(
    type,
    () => {
      return fetchOrders(getFilter(type, filters, 1));
    },
    {
      enabled,
      onSuccess: (data) => {
        setEnabled(false);
        setOrdersCount((prevState) => {
          return {
            ...prevState,
            [type]: data?.totalCount,
          };
        });
      },
      onError: () => {
        setEnabled(false);
      },
    }
  );

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const totalNewOrders = await fetchOrders(getFilter('new', defaultFilters, 1));
        const totalInProgressOrders = await fetchOrders(getFilter('inProgress', defaultFilters, 1));
        const totalCompletedOrders = await fetchOrders(getFilter('completed', defaultFilters, 1));
        setOrdersCount({
          [OrderTypesEnum.new]: totalNewOrders?.totalCount,
          [OrderTypesEnum.inProgress]: totalInProgressOrders?.totalCount,
          [OrderTypesEnum.completed]: totalCompletedOrders?.totalCount,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchAll();
  }, []);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [type]);

  useEffect(() => {
    setEnabled(true);
    refetch();
  }, [filters]);

  const handleOnFilter = (newFilters: any) => {
    const filterBody = {
      ...newFilters,
    };
    setFilters((prevState) => {
      return { ...prevState, ...filterBody };
    });
    setEnabled(true);
  };

  const handleOpenFilterModal = (): void => {
    dispatch(
      modalActions.handleOpenModal({
        content: (
          <OrderFiltersModal
            prevFilters={filters}
            onFilter={handleOnFilter}
            orderTabType={prevOrderTabType ? prevOrderTabType : OrderTypesEnum['new']}
          />
        ),
        options: {
          withCloseButton: false,
          // modalClassName: 'w-[650px] !overflow-visible',
          modalClassName: 'w-full sm:w-[650px] !overflow-visible',
          contentClassName: '!overflow-visible',
        },
      })
    );
  };

  if (!Object.keys(OrderTypesEnum).includes(type)) return <Navigate to="/orders/new" />;

  return (
    <PageWrapper title="Заявки">
      <section className="flex flex-col gap-6">
        {isFetching ? (
          <Spinner />
        ) : (
          <OrdersTabs ordersCounts={ordersCount} isFetching={isFetching} activeType={type} />
        )}
        {isFetching ? (
          <TableLoading />
        ) : (
          <>
            {data?.totalCount === 0 ? (
              <div className="min-h-[90vh] overflow-hidden rounded-[10px] bg-base shadow-3xl">
                <FilterBlock
                  countFilters={determinateFiltersCount(filters)}
                  onFilterButtonClick={handleOpenFilterModal}
                />
                {type == 'new' ? (
                  <div className="max-w-[550px] sm:p-4">
                    <NoneOrders
                      icon={
                        determinateFiltersCount(filters) ? <IconApplication /> : <NoOrdersIcon />
                      }
                      heading={
                        determinateFiltersCount(filters)
                          ? 'Заявки не найдены'
                          : 'В вашем населенном пункте заявки отсутствуют'
                      }
                      description={
                        determinateFiltersCount(filters)
                          ? 'Измените настройки фильтра и попробуйте еще раз'
                          : 'Попробуйте выбрать другой населенный пункт'
                      }
                      onButtonClick={
                        determinateFiltersCount(filters)
                          ? undefined
                          : () => navigate(`/profile/private`)
                      }
                    />
                  </div>
                ) : (
                  <>
                    <div className="max-w-[550px] sm:p-4">
                      <NoneOrders
                        icon={<IconApplication />}
                        heading="Заявки не найдены"
                        description={
                          determinateFiltersCount(filters)
                            ? 'Измените настройки фильтра и попробуйте еще раз'
                            : ''
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                {isSmall ? (
                  <>
                    <FilterBlock
                      countFilters={determinateFiltersCount(filters)}
                      onFilterButtonClick={handleOpenFilterModal}
                    />
                    {data?.pagedList &&
                      data?.pagedList.map((order: any) => (
                        <OrderCardMobile key={order.id} order={order} />
                      ))}
                  </>
                ) : (
                  <TableCard>
                    <FilterBlock
                      countFilters={determinateFiltersCount(filters)}
                      onFilterButtonClick={handleOpenFilterModal}
                    />
                    <Table
                      tableHeading={
                        executorType === ExecutorTypeEnum.Subcontractor
                          ? subcontractorOrdersTableHeadings[type]
                          : ordersTableHeadings[type]
                      }
                      tableData={(data?.pagedList || []) as unknown as TableData[]}
                      onRowClick={handleRowClick}
                    />
                  </TableCard>
                )}
              </>
            )}
          </>
        )}
      </section>
    </PageWrapper>
  );
};

export default OrdersPage;
