import { ExecutorTypeEnum } from 'enums/executorType';
import { step1Schema } from '../step1/consts';
import { step2Schema } from '../step2/consts';
import { step4Schema, step4SchemaWithCities } from '../step4/consts';
import { step3SchemaIndividual, step3SchemaSelfemployed } from '../step3/consts';

export enum RequestHandler {
  DRAFT = 'DRAFT',
  MODERATION = 'MODERATION',
  SECONDARY = 'SECONDARY',
}

export const getFinalSchema = (executorType: ExecutorTypeEnum, parentId: number) => {
  const step3Schema =
    executorType === ExecutorTypeEnum.Selfemployed
      ? step3SchemaSelfemployed
      : step3SchemaIndividual;
  const step4actual = parentId === 0 ? step4SchemaWithCities : step4Schema;
  const finalSchema = step1Schema.concat(step2Schema).concat(step4actual).concat(step3Schema);
  return finalSchema;
};
