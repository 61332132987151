import { FC } from 'react';
import { Slider } from './Slider';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { CustomHelmet } from 'components/CustomHelmet';

const Help: FC = () => {
  return (
    <PageWrapper title="Добро пожаловать">
      {/* <CustomHelmet title="Инструкция" /> */}
      <Slider />
    </PageWrapper>
  );
};

export default Help;
