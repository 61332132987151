import { GerbIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { getModerationRequestStatus } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestStatus';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { Step2Fields } from './Step2Fields';
import { checkNumber, checkSeries, checkUnitCode } from './consts';
import { Step2Payload } from './types';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getExecutorNavigation } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getExecutorNavigation';
import { format } from 'date-fns';

export const Step2 = () => {
  const { status, passport, executorPhotos } = useAppSelector(getModerationRequestData);
  const { created } = useAppSelector(getProfile);
  const isNavigateDisabled = useAppSelector(getExecutorNavigation);
  const disableNavigate = (value: boolean) => {
    dispatch(moderationRequestActions.setIsNavigateDisabled(value));
  };
  const { pathname } = useLocation();
  const step = pathname.split('step')[1];

  const isFormDisabled = useAppSelector(getModerationRequestStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Step2Payload>({
    defaultValues: {
      series: passport.series,
      number: passport.number,
      dateOfIssue: passport.dateOfIssue,
      issuedBy: passport.issuedBy,
      unitCode: passport.unitCode,
      files: passport.files,
      registrationAddress: passport.registrationAddress,
      birthPlace: passport.birthPlace,
      executorPhotos: executorPhotos.files,
    },
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<Step2Payload> = (data, event) => {
    clearErrors();
    if (typeof data.dateOfIssue === 'object') {
      data.dateOfIssue = data.dateOfIssue && format(data.dateOfIssue, 'yyyy-MM-dd');
    }
    let toastErrors: string[] = [];
    if (data.series) {
      toastErrors = checkSeries(data.series, toastErrors, setError);
    }
    if (data.number) {
      toastErrors = checkNumber(data.number, toastErrors, setError);
    }
    if (data.unitCode) {
      toastErrors = checkUnitCode(data.unitCode, toastErrors, setError);
    }
    if (toastErrors.length) {
      const uniqueMessages = new Set(toastErrors);
      Array.from(uniqueMessages).forEach((message: string) => {
        toast.error(message);
      });
      return;
    }
    dispatch(moderationRequestActions.setPassportData(data));

    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as HTMLButtonElement;
    if (isNavigateDisabled) {
      toast.error('Дождитесь удаления файла');
    } else if (submitter.name === 'forward') {
      const nextStep = +step + 1;
      navigate(`/moderation-request/step${nextStep}`);
    } else if (submitter.name === 'back') {
      const prevStep = +step - 1;
      prevStep
        ? prevStep && navigate(`/moderation-request/step${prevStep}`)
        : navigate(`/moderation-request`);
    }
  };

  return (
    <FormWrapper title="Паспортные данные" status={status} created={created} Icon={GerbIcon}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Step2Fields
          clearErrors={clearErrors}
          control={control}
          errors={errors}
          isFormDisabled={isFormDisabled}
          disableNavigate={disableNavigate}
        />
        <FormControl errors={errors} />
      </form>
    </FormWrapper>
  );
};
