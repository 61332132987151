import { LightningBolt } from 'assets';
import { ReactComponent as Document } from 'assets/to-document.svg';
import { differenceInHours, parse } from 'date-fns';
import { Link, useParams } from 'react-router-dom';

import Customer from 'components/Customer';

import { IconLightning, MobileNotification } from 'assets/icons';
import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { useTimeRemaining } from 'hooks/useTimeRemaining';
import { OrderTypesEnum } from 'pages/Orders/enums';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import { getTimezone } from 'reduxStore/reducers/ProfileSlice/selectors/getTimezone';
import { ExecutorTypeEnum } from 'reduxStore/reducers/ProfileSlice/types';
import { OrderForListCard } from 'types';
import { Statuses } from 'types/status';
import { formatAmount, getDateWithTimezone, isVisibleInStatus } from 'utils/helpers';
import { IS_SMALL_DEVICE, ROUBLE_SIGN, SMALL_DEVICE_MEDIA_QUERY } from 'utils/settings';

interface IOrderCardProps {
  order: OrderForListCard;
}

export const OrderCardMobile = ({ order }: IOrderCardProps) => {
  const {
    id: orderId,
    targetDate,
    created,
    address,
    totalCost,
    clientId,
    // notification: { hasOrderChatNotification },
    cityName,
    status,
    clientName,
    name,
    customId,
    estimatedCost,
    newChatMessages,
    distanceInMeters,
    isExactDistance,
  } = order;

  const { type = OrderTypesEnum.new } = useParams<{ type: OrderTypesEnum }>();

  const executorType = useAppSelector(getExecutorType);
  const timeZone = useAppSelector(getTimezone);

  const isSmallDevice = useMediaQuery(SMALL_DEVICE_MEDIA_QUERY);
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  const { remainingTime } = useTimeRemaining({ targetDate: order.targetDate, timeZone });

  /**
   * Для "новых" заявок выводим контрольный срок, для заявок "в работе" - оставшееся время, у "завершенных" - ничего
   */
  const RemainingTerm = () => {
    if (isVisibleInStatus('new', status)) {
      return (
        <p className="footnote-normal text-text-50">
          <IconLightning className="text-[#FF992C]" />
          <span className="ml-1.5">Срок: </span>
          {getDateWithTimezone(targetDate, timeZone)}
        </p>
      );
    } else if (isVisibleInStatus('inProgress', status)) {
      return (
        <p className="footnote-normal text-text-100">
          {/* <IconLightning className="text-[#FF992C]" /> */}
          <span>Срок: </span>
          {remainingTime}
        </p>
      );
    }
    return null;
  };

  /**
   * Выводим иконку "срочности", если до конца срока исполнения меньше 3 часов
   */
  const NoticeIcon = () => {
    if (
      differenceInHours(
        parse(getDateWithTimezone(targetDate, timeZone), 'dd.MM.yyyy HH:mm', new Date()),
        new Date()
      ) < 3 &&
      isVisibleInStatus('new', status)
    ) {
      return (
        <div className="flex h-[20px] w-[20px] items-center justify-center">
          <img src={LightningBolt} alt="Срочно" />
        </div>
      );
    }
    return null;
  };

  const CardName = () => {
    if (isVisibleInStatus('new', status) || isVisibleInStatus('inProgress', status)) {
      return <p className="callout-bold text-text-100">{name}</p>;
    }
    if (isVisibleInStatus('review', status)) {
      return (
        <span className="footnote-normal text-text-50">Оставшееся время для исполнителя:</span>
      );
    }
    if (isVisibleInStatus('completed', status) && !isSmall) {
      if (executorType !== 3) {
        return <span className="footnote-normal text-text-50">Стоимость:</span>;
      } else {
        return <span className="footnote-normal text-text-50">{name}</span>;
      }
    }
    return null;
  };

  const CardDescription = () => {
    const integer = Math.floor(estimatedCost);
    const fraction = (estimatedCost - integer).toFixed(2).substring(1);

    if (isVisibleInStatus('new', status)) {
      if (executorType !== 3) {
        return (
          <div>
            {/* <p className="mb-1 text-text-50">Стоимость</p> */}
            <p className="mt-4">
              <span className="title-bold">{integer}</span>
              <span className="callout-normal">
                {fraction}&nbsp;{ROUBLE_SIGN}
              </span>
            </p>
          </div>
        );
      } else {
        return null;
      }
    }
    if (isVisibleInStatus('review', status)) {
      return <p className="title-bold">{remainingTime}</p>;
    }
    if (isVisibleInStatus('completed', status) && !isSmall) {
      if (executorType !== 3) {
        return (
          <p className="title-bold">
            {formatAmount(estimatedCost || 0)}&nbsp;{ROUBLE_SIGN}
          </p>
        );
      } else {
        return null;
      }
    }
    return null;
  };

  const CardAddress = () => {
    const distanceInKiloMeters = distanceInMeters && distanceInMeters / 1000;
    return isVisibleInStatus('new', status) || isVisibleInStatus('inProgress', status) ? (
      <div className="flex flex-col">
        <p className="mt-1">{address}</p>
        {distanceInMeters !== undefined && (
          <div>
            <p className="mt-1 text-xs">
              Расстояние, км (*, не точно)
              <span className="font-bold text-text-100">
                {' '}
                {distanceInKiloMeters?.toFixed(1)}
                {!isExactDistance && '*'}
              </span>
            </p>
          </div>
        )}
      </div>
    ) : null;
  };

  const CardCreatedDate = () => {
    return isVisibleInStatus('new', status) ? (
      <p>
        <span className="font-semibold">Дата: </span>
        {getDateWithTimezone(created, timeZone, 'dd.MM.yyyy')}
      </p>
    ) : null;
  };

  if (isSmallDevice || executorType !== ExecutorTypeEnum.Subcontractor) {
    return (
      <Link to={`/orders/${type}/${orderId}`}>
        <div className="flex w-full gap-3 rounded-[10px] px-3.5 py-4 shadow md:px-4">
          {isVisibleInStatus('new', status) && (
            <div className="flex max-w-[20%] gap-1">
              <Customer clientId={clientId} />
            </div>
          )}
          <div className="flex flex-1 flex-col gap-2">
            <div className="flex items-center justify-between">
              {!isVisibleInStatus('new', status) && (
                <>
                  {status > 1 && Statuses[status] ? (
                    <div>
                      <span className={`tag ${Statuses[status].color} caption-normal text-white`}>
                        {Statuses[status].label ? Statuses[status].label : Statuses[status].name}
                      </span>
                      {newChatMessages && <MobileNotification className="ml-2" />}
                    </div>
                  ) : (
                    <span />
                  )}
                  <p className="text-text-50">{customId}</p>
                </>
              )}
            </div>
            {isVisibleInStatus('new', status) && (
              <div className="flex items-center justify-between">
                <span className="flex gap-1">
                  <RemainingTerm />
                </span>
                <p className="text-text-50">{customId}</p>
              </div>
            )}
            <div>
              <CardName />
            </div>
            <div>
              <CardDescription />
            </div>
            <div>
              <CardAddress />
            </div>
            {!isVisibleInStatus('new', status) && (
              <>
                <hr className="border-[#e3e3e3]" />
                <div className="flex items-center justify-between">
                  <span className="flex gap-1">
                    <RemainingTerm />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div className="grid-order-card mb-2 w-full select-none items-center gap-2 rounded-xl px-2 py-4 text-xs shadow-3xl transition hover:bg-blue-light md:px-4">
      <div className="relative">
        {/*{hasOrderChatNotification && (
          <div className="absolute -top-5 right-1 flex h-5 w-5 items-center justify-center rounded-full bg-teal">
            <LetterIcon className="text-white" width={14} />
          </div>
        )}*/}
        <span>{customId}</span>
      </div>
      <span>{clientName}</span>
      <span>{getDateWithTimezone(created, timeZone, 'dd.MM.yyyy HH:mm')}</span>
      <span>{cityName}</span>
      <span>{getDateWithTimezone(targetDate, timeZone, 'dd.MM.yyyy HH:mm')}</span>
      <Link to={`/orders/${type}/${orderId}`} className="mx-auto">
        <Document className="text-blue" />
      </Link>
    </div>
  );
};
