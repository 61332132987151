import { useState } from 'react';
import { Control, Controller, UseFormClearErrors } from 'react-hook-form';
import { ActionMeta, DropdownIndicatorProps, MultiValue, components } from 'react-select';

import { OptionType, Select, SelectProps } from 'ui/Select';

import { ArrowIcon, CloseIcon } from 'assets/icons';
import useDebounce from 'hooks/useDebounce';
import { getExtendedSuggestions } from 'services';
import { ExtendedSuggestion, SettlementShort } from 'types';
import { getUniqueCities } from 'utils/helpers';
import { ReactComponent as DropdownArrowIcon } from 'assets/dropdown-arrow.svg';
import classNames from 'classnames';

interface FieldCitiesSelectProps extends Omit<SelectProps, 'onChangeValue'> {
  name: string;
  control: Control<any>;
  onChangeValue?: (
    value: MultiValue<OptionType<SettlementShort | ExtendedSuggestion>>,
    meta?: ActionMeta<OptionType>
  ) => void;
  type: 1 | 2; // 1 - города, 2 - регионы
  customComponents?: any;
  clearErrors?: UseFormClearErrors<any>;
}

export default function FieldSuggestionsCitiesSelect({
  control,
  isMulti = false,
  name,
  label,
  onChangeValue,
  type,
  customComponents,
  clearErrors,
  ...rest
}: FieldCitiesSelectProps) {
  const [cities, setCities] = useState<ExtendedSuggestion[]>([]);

  const [isCitiesLoading, setIsCitiesLoading] = useState(false);

  const getCities = async (template: string) => {
    setIsCitiesLoading(true);
    const response = await getExtendedSuggestions(template, type);
    if (response.status === 200) {
      // Города
      if (type === 1) {
        const filtered = response.data.filter((item) => !item.isMarked);
        setCities(filtered);
      } else {
        setCities(response.data);
      }
    }
    setIsCitiesLoading(false);
  };

  const debouncedGetCities = useDebounce(getCities, 500);

  const transformSuggestionToSelectOption = (array: ExtendedSuggestion[]) => {
    return Array.isArray(array)
      ? array.map((item) => ({
          label: item.suggestion.value,
          value: item,
        }))
      : [];
  };

  return (
    <div className="field-select">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const DropdownIndicator = (props: any) => {
            const { menuIsOpen } = props.selectProps;
            return (
              <components.DropdownIndicator {...props}>
                <CloseIcon
                  className="mr-1 text-text-50 hover:text-text-100"
                  onClick={() => {
                    field.onChange([]);
                    onChangeValue && onChangeValue([] as MultiValue<OptionType<SettlementShort>>);
                    clearErrors && clearErrors('serviceAreas');
                  }}
                />
                <DropdownArrowIcon
                  className={classNames('ml-1 h-3.5 w-3.5', {
                    'rotate-180': menuIsOpen,
                  })}
                />
              </components.DropdownIndicator>
            );
          };
          return (
            <Select
              name={name}
              /** TODO Если убрать any, то ts ругается, но проект компилируется */
              // components={{ MultiValueRemove, DropdownIndicator } as any}
              components={{ ...customComponents, DropdownIndicator } as any}
              value={field.value}
              label={label}
              variant="areas"
              isMulti
              onChangeValue={(value, meta) => {
                field.onChange(value);
                clearErrors && clearErrors('serviceAreas');
                onChangeValue &&
                  onChangeValue(value as MultiValue<OptionType<SettlementShort>>, meta);
              }}
              options={transformSuggestionToSelectOption(getUniqueCities(cities, field.value))}
              isLoading={isCitiesLoading}
              onInputChange={(newValue: string) => {
                debouncedGetCities(newValue);
              }}
              {...rest}
            />
          );
        }}
      />
    </div>
  );
}
